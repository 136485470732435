import React from "react";
import { useSelector } from "react-redux";
import get from "lodash.get";
import groupBy from "lodash.groupby";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import domScrollTo from "../utils/domScrollTo";
import ProductsList from "../components/ProductsList";
import useGoTo from "../hooks/useGoTo";
import {
  getGroupAvailability,
  getProductAvailability,
} from "../hooks/useWorkingTime";
import Avatar from "../components/Avatar";

export default function GroupedProductsView() {
  const groupMenuRef = React.useRef(null);
  const goTo = useGoTo();
  const search = useSelector((state) => state.search);
  const { groups, items: products } = useSelector((state) => state.products);
  const groupsRefs = React.useRef({});
  const [selectedGroupId, setSelectedGroupId] = React.useState(null);

  groups.forEach((group) => {
    if (!groupsRefs.current[group.id]) {
      groupsRefs.current[group.id] = React.createRef();
    }
  });

  const goToGroup = (groupId) => {
    const groupContainer = groupsRefs.current[groupId];
    if (!groupContainer) {
      return;
    }

    const node = groupContainer.current.parentNode;
    const nodeStyle = window.getComputedStyle(node);
    const paddingTop = nodeStyle.getPropertyValue("padding-top");
    const paddingTopInt = parseInt(paddingTop);

    setSelectedGroupId(groupId.toString());
    setTimeout(() => {
      domScrollTo(
        get(groupContainer.current, "offsetTop", 0) - paddingTopInt - 10
      );
    }, 350);
  };

  const availableProducts = products.filter(
    (p) => getProductAvailability(p).isAvailable
  );

  const filteredProducts = availableProducts.filter(
    (p) =>
      (p.name || "").toLowerCase().includes(search.content.toLowerCase()) ||
      (p.details || "").toLowerCase().includes(search.content.toLowerCase())
  );
  const filteredProductsByGroup = groupBy(filteredProducts, "groupId");

  const onProductSelect = (productId) => goTo(`/product/${productId}`);

  const onSelectAccordion = (groupId) => {
    setSelectedGroupId(groupId);
  };

  return (
    <div>
      <Navbar bg="white" fixed="top" ref={groupMenuRef}>
        <Nav class="nav-instagram-menu">
          {groups
            .filter(
              (g) =>
                filteredProductsByGroup[g.id] &&
                getGroupAvailability(g).isAvailable
            )
            .map((group) => (
              <Nav.Item key={group.id} onClick={() => goToGroup(group.id)}>
                <Nav.Link
                  href="#"
                  className={`d-flex flex-column align-items-center group-menu__item ${
                    group.id.toString() === selectedGroupId
                      ? " font-weight-bold"
                      : ""
                  }`}
                >
                  <div className="d-flex justify-content-center align-items-center rounded-circle group-menu__image-container mb-2">
                    {group.image ? (
                      <div
                        className="rounded-circle group-menu__image"
                        style={{
                          "background-image": `url("${group.image}")`,
                        }}
                      />
                    ) : (
                      <Avatar
                        name={group.name}
                        className="bg-danger group-menu__image"
                      />
                    )}
                  </div>
                  {group.name}
                </Nav.Link>
              </Nav.Item>
            ))}
        </Nav>
      </Navbar>
      <Accordion
        onSelect={onSelectAccordion}
        activeKey={selectedGroupId}
        className="grouped-products-instagram"
      >
        {groups
          .filter(
            (g) =>
              filteredProductsByGroup[g.id] &&
              getGroupAvailability(g).isAvailable
          )
          .map((group) => (
            <Card
              key={group.id}
              ref={groupsRefs.current[group.id]}
              className="border-0 mb-3"
            >
              <Card.Header
                className="btn-image border-0 p-0 mx-2"
                style={{
                  "background-image": `linear-gradient(50deg, rgba(0,0,0,1) 0%, ${
                    group.image ? "rgba(255,255,255,0)" : "#dc3545"
                  } 55%), url("${group.image}")`,
                }}
              >
                <Accordion.Toggle
                  className={`w-100 text-left text-white btn-image-label`}
                  as={Button}
                  variant="link"
                  eventKey={`${group.id}`}
                >
                  <h2 className="font-weight-bold mt-4 mb-4">{group.name}</h2>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={`${group.id}`}>
                <Card.Body className="px-0">
                  <ProductsList
                    products={filteredProductsByGroup[group.id]}
                    onProductSelect={onProductSelect}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
      </Accordion>
    </div>
  );
}
