import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.style.css";
import React, { useRef, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { STATE_STEP } from "./app/ducks/consts";
import { fetchAppEssentials } from "./app/ducks/appStateSlice";
import { setInLoco } from "./app/ducks/shopSlice";
import LoadingWall from "./app/components/LoadingWall";
import ErrorWall from "./app/components/ErrorWall";
import ShopHomeScreen from "./app/screens/ShopHomeScreen";
import ProductScreen from "./app/screens/ProductScreen";
import GroupScreen from "./app/screens/GroupScreen";
import CheckoutScreen from "./app/screens/CheckoutScreen";
import OrderStatusScreen from "./app/screens/OrderStatusScreen";
import MarketplaceScreen from "./app/screens/MarketplaceScreen";
import UnknownErrorGuard from "./app/components/UnknownErrorGuard";
import useMPSDK from "./app/hooks/useMPSDK";
import FidelityScreen from "./app/screens/FidelityScreen";
import LoginScreen from "./app/screens/LoginScreen";
import RegisterScreen from "./app/screens/RegisterScreen";
import ConfirmationScreen from "./app/screens/ConfirmationScreen";

const availableLocations = ["mesa", "comanda", "autoatendimento"];

export default function App() {
  const readyRef = useRef(false);
  const dispatch = useDispatch();
  const [params, setParams] = useState(null);
  const { appState, shop } = useSelector((state) => state);

  const mercadoPagoKey = shop ? shop.mercadoPagoKey : null;
  useMPSDK(mercadoPagoKey);

  React.useEffect(() => {
    const [, restaurant, orderLocation, tableId] =
      window.location.pathname.split("/");
    const isInLoco = availableLocations.includes(orderLocation);
    const orderLocationPath = isInLoco ? `/:orderLocation/:mesaId` : "";
    const finalPath = `/:namespace${orderLocationPath}`;
    setParams({
      restaurant,
      orderLocation,
      tableId,
      isInLoco,
      finalPath,
    });
  }, []);

  React.useEffect(() => {
    if (params === null) return;
    const routeStatus = window.location.pathname.includes("status");
    dispatch(setInLoco(params));
    dispatch(
      fetchAppEssentials(params.restaurant, params.isInLoco, routeStatus)
    );
  }, [dispatch, params]);

  if (
    appState.step === STATE_STEP.INITIAL ||
    appState.step === STATE_STEP.LOADING ||
    params === null
  ) {
    return <LoadingWall />;
  }

  if (appState.step === STATE_STEP.ERROR) {
    return <ErrorWall />;
  }

  if (readyRef.current === false) {
    if (window.posNativeInterface) window.posNativeInterface.loadCompleted();
    readyRef.current = true;
  }

  const { finalPath } = params;
  return (
    <UnknownErrorGuard>
      <BrowserRouter>
        <ToastContainer />
        <Switch>
          <Route path={`${finalPath}/status/:shortReference`}>
            <OrderStatusScreen />
          </Route>
          <Route path={`${finalPath}/product/:productId/order/:orderUuid`}>
            <ProductScreen />
          </Route>
          <Route path={`${finalPath}/product/:productId`}>
            <ProductScreen />
          </Route>
          <Route path={`${finalPath}/group/:groupId`}>
            <GroupScreen />
          </Route>
          <Route path={`${finalPath}/checkout`}>
            <CheckoutScreen />
          </Route>
          <Route path={`${finalPath}/fidelity`}>
            <FidelityScreen />
          </Route>
          <Route path={`${finalPath}/login`}>
            <LoginScreen />
          </Route>
          <Route path={`${finalPath}/register`}>
            <RegisterScreen />
          </Route>
          <Route path={`${finalPath}/confirmation`}>
            <ConfirmationScreen />
          </Route>
          <Route path={finalPath}>
            <ShopHomeScreen />
          </Route>

          <Route path="/:namespace/marketplace/">
            <MarketplaceScreen />
          </Route>
          <Route path="/">
            <ErrorWall />
          </Route>
        </Switch>
      </BrowserRouter>
    </UnknownErrorGuard>
  );
}
