import React from "react";
import QRCode from "qrcode.react";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { makeStyles } from "@material-ui/core/styles";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { STATUS_DETAILS } from "../ducks/consts";
import RemoteOrder from "../services/remote-order";
import CommonHeader from "../containers/CommonHeader";
import Divider from "../components/Divider";
import Loading from "../components/Loading";
import mpURL from "../assets/payment-flat-rounded/mercado-pago.svg";
import posVertical from "../assets/payment-flat-rounded/pos-stone-vertical.jpg";
import { POS_PRESENTIAL_OPTION } from "../ducks/checkoutSlice";
import SelfCheckoutQRCodeStatus from "./Selfcheckout/SelfCheckoutQRCodeStatus";
import SelfCheckoutTotemStatus from "./Selfcheckout/SelfCheckoutTotemStatus";
import OrderDetailsStatus from "./OrderDetailsStatus";

const useStyles = makeStyles(() => ({
  status: {
    margin: "9px",
    paddingLeft: "18px",
    paddingBottom: "18px",
    border: "1px solid",
    borderTop: 0,
    borderBottom: 0,
    borderRight: 0,
    borderColor: "#ff0000",
  },
  lastStatus: {
    border: 0,
    paddingBottom: 0,
    marginBottom: 0,
  },
  qrCode: {
    display: "block",
    margin: "16px",
  },
}));

export default function OrderFollowupScreen() {
  const classes = useStyles();
  const routeMatch = useRouteMatch();
  const [copyPIXError, setCopyPIXError] = React.useState(false);
  const [isPIXHelpVisible, setPIXHelpVisible] = React.useState(false);
  const [isModalVisible, setModalVisible] = React.useState(true);
  const [isInitialLoading, setInitialLoading] = React.useState(true);
  const [status, setStatus] = React.useState([]);
  const [order, setOrder] = React.useState({
    id: null,
    shopId: null,
    createdAt: null,
    toGo: null,
    toDelivery: null,
  });

  const showOnlyStatus = useSelector((state) => state.appState.showOnlyStatus);
  const shop = useSelector((state) => state.shop);
  const shortReference = parseInt(routeMatch.params.shortReference, 10);
  const lastCache = useSelector((state) => state.checkout.lastCheckoutCache);
  const cache =
    lastCache && shortReference === lastCache.shortReference ? lastCache : null;
  const pollingRef = React.useRef(null);
  const pollingDelay = 10000;

  React.useEffect(() => {
    if (cache) {
      setOrder({
        id: cache.orderId,
        shopId: cache.shopId,
        createdAt: cache.createdAt,
        deliveryDateTime: cache.deliveryDateTime,
        toGo: cache.checkout.toGo,
        toDelivery: cache.checkout.toDelivery,
        isInLoco: cache.isInLoco,
        tableId: cache.tableId,
        isTakingHome: cache.checkout.isTakingHome,
      });
    } else {
      RemoteOrder.retrieveOrder(shop.id, shortReference)
        .then((order) => setOrder({ ...order, shopId: shop.id }))
        .catch((err) => {
          console.error(err);
          setInitialLoading(false);
        });
    }
  }, [cache, shop.id, shortReference]);

  React.useEffect(() => {
    if (!order || !order.id) return;

    const doStatusFetch = async () => {
      var hasFinish = false;
      try {
        const data = await RemoteOrder.retrieveStatus(order.id);
        const finish = order.toGo
          ? STATUS_DETAILS.READY_TO_DELIVER
          : STATUS_DETAILS.DISPATCHED;
        hasFinish = data.find((s) => s.code === finish.code);
        setStatus(data);
      } catch (err) {
        console.error(err);
      } finally {
        setInitialLoading(false);
        if (hasFinish) return;
        pollingRef.current = setTimeout(doStatusFetch, pollingDelay);
      }
    };

    pollingRef.current = setTimeout(doStatusFetch, 1000);
    return () => clearTimeout(pollingRef.current);
  }, [order]);

  if (shop.isSelfchekoutQRCode) {
    return (
      <SelfCheckoutQRCodeStatus
        shortReference={shortReference}
        shop={shop}
        cache={cache}
        order={order}
        status={status}
      />
    );
  }

  if (shop.isSelfchekoutTotem) {
    return (
      <SelfCheckoutTotemStatus
        shortReference={shortReference}
        cache={cache}
        order={order}
        status={status}
      />
    );
  }

  if (isInitialLoading) {
    return (
      <>
        <CommonHeader text="Acompanhe seu pedido" showBack={!showOnlyStatus} />
        <Divider className="mb-3" />
        <Container>
          <Loading message="Carregando pedido..." />
        </Container>
      </>
    );
  }

  if (!order || !order.id) {
    return (
      <>
        <CommonHeader text="Acompanhe seu pedido" showBack={!showOnlyStatus} />
        <Divider className="mb-3" />
        <Container>
          <Alert variant="secondary">Pedido não existe</Alert>
        </Container>
      </>
    );
  }

  const presentialPosPayment =
    order.isInLoco &&
    shop.inLocoRequirePayment &&
    shop.presentialPosPayment &&
    cache &&
    cache.checkout.payment === POS_PRESENTIAL_OPTION.name;
  const paymentQRData = cache ? cache.paymentQRData : null;
  const paymentPreference = cache ? cache.paymentPreference : null;
  const showPaymentStep =
    paymentQRData || paymentPreference || presentialPosPayment;

  const checkout = cache ? cache.checkout : null;
  const mode = order.toGo
    ? "para retirar"
    : order.toDelivery
    ? "para entrega"
    : `na loja`;
  const inLocoTableText = Number(order.tableId)
    ? `${shop.inLocoTitle} ${order.tableId}`
    : order.tableId;
  const inLocoText =
    checkout && checkout.inLocoLocation
      ? `${checkout.inLocoLocation} ${inLocoTableText}`
      : inLocoTableText;
  const formatter = new Intl.DateTimeFormat("pt-BR", {
    hour: "numeric",
    minute: "numeric",
  });

  const cancelled = status.find(
    (s) => s.code === STATUS_DETAILS.CANCELLED.code
  );
  const orderedSteps = cancelled
    ? [STATUS_DETAILS.DONE, STATUS_DETAILS.CANCELLED]
    : order.toDelivery
    ? [
        STATUS_DETAILS.DONE,
        ...(showPaymentStep ? [STATUS_DETAILS.PAYMENT] : []),
        STATUS_DETAILS.CONFIRMED,
        STATUS_DETAILS.DISPATCHED,
      ]
    : [
        STATUS_DETAILS.DONE,
        ...(showPaymentStep ? [STATUS_DETAILS.PAYMENT] : []),
        STATUS_DETAILS.CONFIRMED,
        STATUS_DETAILS.READY_TO_DELIVER,
      ];

  const orderedStepsRemote = orderedSteps.map((o, i) => ({
    ...o,
    remoteStatus: i === 0 ? order : status.find((s) => s.code === o.code),
  }));

  const stepWait = orderedStepsRemote.find((o) => !o.remoteStatus);

  const showMessageOrderSuccess = shop.messageOrderSuccess && order.isInLoco;
  const cancelModal = () => setModalVisible(false);
  const cancelPIXHelp = () => setPIXHelpVisible(false);
  const onCopyQRCode = async () => {
    try {
      await navigator.clipboard.writeText(paymentQRData);
      setCopyPIXError(false);
    } catch (err) {
      console.log(err);
      setCopyPIXError(true);
    } finally {
      setPIXHelpVisible(true);
    }
  };
  const onCheckout = () => {
    window.mercadoPago.checkout({
      preference: {
        id: paymentPreference.id,
      },
      autoOpen: true,
    });
  };

  return (
    <>
      <Modal
        show={isModalVisible && showMessageOrderSuccess}
        onHide={cancelModal}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">Aviso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="w-100 text-center"
            dangerouslySetInnerHTML={{ __html: shop.messageOrderSuccess }}
          />
          <p>Para concluir seu pagamento siga as intruções:</p>
          {presentialPosPayment && (
            <PosPaymentInstructions
              shortReference={shortReference}
              insideModal={true}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={isPIXHelpVisible} onHide={cancelPIXHelp}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Instruções</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {copyPIXError && (
            <>
              <Alert variant="info">
                Copie o código do PIX e siga os passos abaixo para realizar o
                pagamento:
              </Alert>
              <Alert variant="warning">
                <span>Código PIX:</span>
                <p className="mt-3">{paymentQRData}</p>
              </Alert>
            </>
          )}
          {!copyPIXError && (
            <Alert variant="info">
              O código pix foi copiado para sua área de transferência. Agora
              siga os passos abaixo para realizar o pagamento:
            </Alert>
          )}
          <ListGroup variant="flush">
            <ListGroup.Item>
              1. Acesse o aplicativo do seu banco.
            </ListGroup.Item>
            <ListGroup.Item>
              2. Encontre a função <b>"Pix copia e cola"</b>.
            </ListGroup.Item>
            <ListGroup.Item>3. Cole o código pix</ListGroup.Item>
            <ListGroup.Item>4. Confirme o pagamento.</ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>

      <CommonHeader text="Acompanhe seu pedido" showBack={!showOnlyStatus} />
      <Divider className="mb-3" />
      <Container>
        {cache && cache.shopName && (
          <h1 className="mb-2 font-weight-bold">{cache.shopName}</h1>
        )}
        <p className="mb-1 text-muted">
          {"Pedido "}
          <strong>{mode}</strong>
          {` nº: ${shortReference}`}
        </p>
        {order.isInLoco && (
          <p className="mb-1 text-muted">
            {"O pedido será entregue em: "}
            <strong>{inLocoText}</strong>
          </p>
        )}
        {order.isTakingHome && (
          <p className="mb-1 text-muted">Embalado para viagem</p>
        )}
        <p className="mb-1 text-muted">{`Data do pedido: ${formatDateTime(
          order.createdAt
        )}`}</p>
        {order.deliveryDateTime && (
          <p className="m-0 text-muted">{`Entrega prevista: ${formatDateTime(
            order.deliveryDateTime
          )}`}</p>
        )}
        {showMessageOrderSuccess && (
          <Alert variant="info" className="mt-5 text-center">
            <div
              className="w-100 text-center"
              dangerouslySetInnerHTML={{ __html: shop.messageOrderSuccess }}
            />
          </Alert>
        )}
      </Container>
      <Container className="pt-5 pl-5 pb-5">
        {orderedStepsRemote.map((step, stepIndex) => (
          <div key={step.code}>
            <div className="d-flex flex-row align-items-center">
              {step.remoteStatus && !step.cancelled && (
                <CheckCircleIcon fontSize="small" color="error" />
              )}
              {step.remoteStatus && step.cancelled && (
                <CancelIcon fontSize="small" color="error" />
              )}
              {stepWait && step.code === stepWait.code && (
                <CircularProgress size="18px" color="secondary" />
              )}
              {stepWait &&
                !step.remoteStatus &&
                step.code !== stepWait.code && (
                  <RadioButtonUncheckedIcon fontSize="small" color="error" />
                )}
              <strong className="pl-2">
                {stepWait && !step.remoteStatus
                  ? step.labelWaiting
                  : step.labelCompleted}
              </strong>
            </div>
            <div
              className={`${classes.status} ${
                stepIndex === orderedSteps.length - 1 ? classes.lastStatus : ""
              }`}
            >
              {step.remoteStatus && (
                <p>
                  <small className="text-muted">
                    {formatter.format(new Date(step.remoteStatus.createdAt))}
                  </small>
                </p>
              )}

              {step.remoteStatus && (
                <p className="mb-0">
                  <small className="text-muted">{step.completed}</small>
                </p>
              )}

              {!step.remoteStatus && (
                <p className="mb-0">
                  <small className="text-muted">{step.waiting}</small>
                </p>
              )}

              {step.payment && presentialPosPayment && !step.remoteStatus && (
                <PosPaymentInstructions shortReference={shortReference} />
              )}

              {step.payment && paymentQRData && !step.remoteStatus && (
                <>
                  <Button className="mt-4 ml-3 mb-3" onClick={onCopyQRCode}>
                    Copiar PIX
                  </Button>
                  <QRCode
                    className={classes.qrCode}
                    value={paymentQRData}
                    size={200}
                  />
                </>
              )}

              {step.payment && paymentPreference && !step.remoteStatus && (
                <Button className="mt-4 ml-3 mb-3" onClick={onCheckout}>
                  <img
                    src={mpURL}
                    alt="Mercado Pago"
                    height="48"
                    className="pr-3"
                  />
                  Realizar pagamento
                </Button>
              )}
            </div>
          </div>
        ))}
      </Container>
      <OrderDetailsStatus
        shortReference={shortReference}
        cache={cache}
        shop={shop}
        order={order}
        isOrderVisibleInitialValue={true}
      />
    </>
  );
}

function PosPaymentInstructions({ shortReference, insideModal }) {
  return (
    <div className="d-flex flex-row align-items-center justify-content-between mt-4">
      <img
        src={posVertical}
        alt="Mercado Pago"
        height="180"
        className={insideModal ? "ml-4 mr-5" : "pr-3"}
      />
      <ListGroup variant="flush">
        <ListGroup.Item className="px-0">
          <strong>1. </strong>Dirija-se até uma das maquininhas
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <strong>2. </strong>Digite o número do seu pedido.{" "}
          <strong>{shortReference}</strong>
        </ListGroup.Item>
        <ListGroup.Item className="px-0">
          <strong>3. </strong>Realize o pagamento
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
}

function formatDateTime(value) {
  const date = new Date(value);
  return `${date.toLocaleDateString("pt-BR", {
    dateStyle: "short",
  })} ${date.toLocaleTimeString("pt-BR", { timeStyle: "short" })}`;
}
