import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { setOrderScheduling } from "../ducks/checkoutSlice";
import useWorkingTime from "../hooks/useWorkingTime";
import SchedulerDay from "./SchedulerDay";

export default function OrderSchedulerModal({
  isOpen,
  onClose,
  iSchedulingRequired = false,
}) {
  const dispatch = useDispatch();

  const shop = useSelector((state) => state.shop);
  const { toGo, toDelivery } = useSelector((state) => state.checkout);
  const { daysAheadToSchedule, workingScheduleToGo, workingScheduleDelivery } =
    shop;
  const { isWorkingDelivery, isWorkingToGo } = useWorkingTime(shop);

  const workingSchedule = toGo
    ? workingScheduleToGo
    : toDelivery
    ? workingScheduleDelivery
    : {};

  const isStoreWorking = toGo
    ? isWorkingToGo
    : toDelivery
    ? isWorkingDelivery
    : false;

  const orderScheduling = useSelector(
    (state) => state.checkout.orderScheduling
  );

  const availableDaysForScheduling = getAvailableDaysForScheduling(
    daysAheadToSchedule,
    workingSchedule,
    isStoreWorking
  );

  const initialSelectedDay = orderScheduling.isScheduledOrder
    ? orderScheduling.schedule.date
    : availableDaysForScheduling[0];

  const initialSelectedInterval = orderScheduling.isScheduledOrder
    ? orderScheduling.schedule.hoursInterval
    : "";
  const [selectedInterval, setSelectedInterval] = React.useState(
    initialSelectedInterval
  );
  const [selectedDay, setSelectedDay] = React.useState(initialSelectedDay);

  const availableHourIntervalsForScheduling = getAvailableHoursForScheduling(
    selectedDay,
    workingSchedule
  );

  const selectDay = (day) => () => setSelectedDay(day);
  const handleChangeSelectedInterval = (event) =>
    setSelectedInterval(event.target.value);

  const setScheduling = () => {
    if (iSchedulingRequired && (!selectedDay || !selectedInterval)) {
      return;
    }

    const scheduledDate = new Date(selectedDay);
    dispatch(
      setOrderScheduling({
        isScheduledOrder: !!selectedInterval,
        schedule: !!selectedInterval
          ? {
              date: scheduledDate.toISOString("pt-BR", { dateStyle: "short" }),
              hoursInterval: selectedInterval,
            }
          : null,
      })
    );
    onClose();
  };

  const today = new Date();
  const selectedDate = new Date(selectedDay);
  const isTodaySelected = today.getDate() === selectedDate.getDate();
  const getBorderColorForSelectedItem = (baseValue, selectedValue) =>
    baseValue === selectedValue ? "border-danger" : "border-secondary";
  const getTextColorForSelectedItem = (baseDate) =>
    baseDate === selectedDay ? "text-danger" : "text-secondary";

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      dialogClassName="h-100"
      contentClassName="h-100"
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title as="h3">Quando quer receber?</Modal.Title>
      </Modal.Header>
      <div className="d-flex flex-wrap m-3">
        {availableDaysForScheduling
          .map((day) => new Date(day))
          .map((date) => (
            <SchedulerDay
              date={date}
              classNames={"mr-2 mb-2"}
              borderColor={getBorderColorForSelectedItem(
                date.toISOString(),
                selectedDay
              )}
              textColor={getTextColorForSelectedItem(date.toISOString())}
              onClick={selectDay(date.toISOString())}
            />
          ))}
      </div>
      <p className="ml-3 mt-3">Horário:</p>
      <Modal.Body className="pt-0">
        <div className="d-flex flex-column">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="scheduling interval"
              name="scheduling-interval"
              value={selectedInterval}
              onChange={handleChangeSelectedInterval}
            >
              {isTodaySelected && (
                <FormControlLabel
                  className={`d-flex flex-row-reverse justify-content-between mx-0 pl-3 border rounded ${getBorderColorForSelectedItem(
                    "",
                    selectedInterval
                  )}`}
                  value={""}
                  control={<Radio />}
                  label="Agora mesmo"
                />
              )}
              {availableHourIntervalsForScheduling
                .map((interval) => ({
                  startDate: new Date(interval.start),
                  endDate: new Date(interval.end),
                }))
                .map(({ startDate, endDate }) => ({
                  startDate,
                  value: `${startDate.toLocaleString("pt-BR", {
                    timeStyle: "short",
                  })} - ${endDate.toLocaleString("pt-BR", {
                    timeStyle: "short",
                  })}`,
                }))
                .map(({ startDate, value }) => (
                  <FormControlLabel
                    className={`d-flex flex-row-reverse justify-content-between mx-0 my-2 pl-3 border rounded ${getBorderColorForSelectedItem(
                      value,
                      selectedInterval
                    )}`}
                    key={startDate}
                    value={value}
                    control={<Radio />}
                    label={value}
                  />
                ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={setScheduling}
          disabled={iSchedulingRequired && (!selectedDay || !selectedInterval)}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function getAvailableDaysForScheduling(
  daysAheadToSchedule,
  workingSchedule,
  isStoreWorking
) {
  const oneDayStep = 86400000;
  const weekdays = Object.keys(workingSchedule);
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const availableDaysForScheduling = isStoreWorking
    ? [today.toISOString()]
    : [];
  const daysAheadFromToday = Array(daysAheadToSchedule * 2)
    .fill(1)
    .map((item, index) => {
      const date = new Date((item + index) * oneDayStep + today.getTime());
      return date.toISOString();
    });
  return availableDaysForScheduling
    .concat(daysAheadFromToday)
    .filter((day) => {
      const date = new Date(day);
      return weekdays.includes(`${date.getDay()}`);
    })
    .slice(0, daysAheadToSchedule);
}

function getAvailableHoursForScheduling(dayForScheduling, workingSchedule) {
  const schedulingDate = new Date(dayForScheduling);
  const workingScheduleItems = workingSchedule[schedulingDate.getDay()];
  const now = Date.now();

  if (!workingScheduleItems) return [];

  return workingScheduleItems.reduce((schedulingIntervals, item) => {
    const startDate = new Date(
      schedulingDate.getFullYear(),
      schedulingDate.getMonth(),
      schedulingDate.getDate(),
      item.start.hours,
      item.start.minutes,
      0
    );
    const endDate = new Date(
      schedulingDate.getFullYear(),
      schedulingDate.getMonth(),
      schedulingDate.getDate(),
      item.end.hours,
      item.end.minutes,
      0
    );
    const step = 1000 * 60 * 30;
    const hourRange = Math.ceil((endDate - startDate) / step);

    const intervals = Array(hourRange)
      .fill(startDate.getTime())
      .reduce((intervals, startTimestamp, index) => {
        const startStep = index * step;
        const endStep = step + index * step;
        const start = startTimestamp + startStep;
        const end = startTimestamp + endStep;

        if (start < now) {
          return intervals;
        }

        return intervals.concat({
          start,
          end,
        });
      }, []);

    return schedulingIntervals.concat(intervals);
  }, []);
}
