export default function domScrollTo(top, behavior = 'smooth') {
  try {
    window.document.documentElement.scrollTo({ top, behavior });
  } catch {
    console.error('Scroll unavailable for `window.document.documentElement`.');
  }
};

export function domScrollToBottom(behavior = 'smooth') {
  try {
    const top = window.document.body.scrollHeight;
    window.document.documentElement.scrollTo({ top, behavior });
  } catch {
    console.error('Scroll unavailable for `window.document.documentElement`.');
  }
}
