import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Wizard, Steps, Step } from "react-albus";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import toPriceDisplay from "../utils/toPriceDisplay";
import useGoTo from "../hooks/useGoTo";
import useToast from "../hooks/useToast";
import {
  commitOrder,
  cancelOrder,
  incrementOrderQtt,
  decrementOrderQtt,
} from "../ducks/shoppingCartSlice";
import { setUser18Older } from "../ducks/shopSlice";
import { QuestionCard } from "./QuestionsCards";
import qttDisplay from "../utils/qttDisplay";

export default function FloatingProductOrderFooter({
  orderUuid,
  orderUuidEdit,
}) {
  const dispatch = useDispatch();
  const { toastSuccess } = useToast();
  const history = useHistory();
  const goTo = useGoTo();
  const user18Older = useSelector((state) => state.shop.user18Older);
  const { qtt, productId } = useSelector(
    (state) =>
      state.shoppingCart.productOrders.find((o) => o.uuid === orderUuid) || {}
  );
  const product = useSelector((state) =>
    state.products.items.find((p) => p.id === productId)
  );
  const cachedCost = useSelector(
    (state) => state.shoppingCart.cachedCostPerOrder[orderUuid]
  );
  const questions = useSelector(
    (state) => state.products.questionsByProduct[productId]
  );
  const answers = useSelector(
    (state) => state.shoppingCart.answersByOrder[orderUuid]
  );
  const { kilogramStep } = useSelector((state) => state.shop);
  const [show18Years, setShow18Years] = React.useState(false);
  const [showWizard, setShowWizard] = React.useState(false);

  if (!cachedCost) {
    return null;
  }

  const unansweredRequireds = questions.reduce(
    (unansweredRequireds, question) => {
      const answersQtt = answers
        .filter((a) => a.questionId === question.id)
        .reduce((acc, a) => acc + a.qtt, 0);

      if (question.minQtt > 0 && answersQtt < question.minQtt) {
        unansweredRequireds = [...unansweredRequireds, question];
      }

      return unansweredRequireds;
    },
    []
  );

  const { disabled, isKilogram, isAlcoholic } = product;
  const qttMinimum = isKilogram ? kilogramStep : 1;

  const boundPayload = { uuid: orderUuid, isKilogram, kilogramStep };
  const increment = () => dispatch(incrementOrderQtt(boundPayload));
  const decrement = () => dispatch(decrementOrderQtt(boundPayload));
  const addAlcoholicToCart = () => {
    if (isAlcoholic && !user18Older) {
      setShow18Years(true);
      return;
    }

    addToCart();
  };

  const addToCart = () => {
    if (unansweredRequireds.length > 0) {
      setShowWizard(true);
      return;
    }

    dispatch(commitOrder(boundPayload));
    if (orderUuidEdit) {
      dispatch(cancelOrder({ uuid: orderUuidEdit }));
      toastSuccess("Produto alterado!");
    } else {
      toastSuccess("Produto adicionado!");
    }

    if (history.action === "PUSH") {
      history.goBack();
    } else {
      goTo("/");
    }
  };

  return (
    <Container
      as='footer'
      className='d-flex align-items-center pt-3 pb-3 bg-white fixed-bottom'
    >
      <Button
        variant='link'
        onClick={decrement}
        className='flex-shrink-0'
        disabled={qtt <= qttMinimum}
      >
        <RemoveIcon />
      </Button>
      <span className='flex-shrink-0 user-select-none'>
        {qttDisplay(qtt, isKilogram)}
      </span>
      <Button variant='link' onClick={increment} className='flex-shrink-0'>
        <AddIcon />
      </Button>
      <Button
        variant='danger'
        className='d-inline-flex flex-grow-1 justify-content-between p-3'
        onClick={isAlcoholic ? addAlcoholicToCart : addToCart}
        disabled={disabled}
      >
        <span>
          {disabled
            ? "Produto em falta"
            : orderUuidEdit
            ? "Alterar"
            : "Adicionar"}
        </span>
        <span>
          <AddShoppingCartIcon /> {toPriceDisplay(cachedCost)}
        </span>
      </Button>
      <Dialog18Years
        dispatch={dispatch}
        show18Years={show18Years}
        setShow18Years={setShow18Years}
        addToCart={addToCart}
      />
      <QuestionsWizard
        showWizard={showWizard}
        setShowWizard={setShowWizard}
        orderUuid={orderUuid}
        unansweredRequireds={unansweredRequireds}
        addToCart={addToCart}
      />
    </Container>
  );
}

function Dialog18Years({ dispatch, show18Years, setShow18Years, addToCart }) {
  const [under18Years, setUnder18Years] = React.useState(null);
  const handleClose = () => {
    setShow18Years(false);
    setUnder18Years(null);
  };

  const handleNo = () => setUnder18Years(true);

  const handleYes = (e) => {
    dispatch(setUser18Older({ user18Older: true }));
    handleClose();
    addToCart(e);
  };

  return (
    <Modal show={show18Years} onHide={handleClose}>
      <Modal.Body>
        {under18Years === null && (
          <div className='p-3'>
            <h1 className='py-3 text-center'>Você tem 18 anos ou mais?</h1>
            <div className='py-3 w-100 d-inline-flex flex-grow-1 justify-content-between'>
              <Button
                className='w-50 mr-2'
                variant='outline-danger'
                onClick={handleNo}
              >
                NÃO
              </Button>
              <Button
                className='w-50 ml-2'
                variant='danger'
                type='submit'
                onClick={handleYes}
              >
                SIM
              </Button>
            </div>
          </div>
        )}
        {under18Years === true && (
          <div className='p-3'>
            <h1 className='py-3 text-center'>
              Você precisa ter 18 anos ou mais para consumir bebidas alcoólicas.
            </h1>
            <h1 className='py-3 text-center'>Relaxe, o tempo passa rápido!</h1>
            <div className='py-3 w-100 d-inline-flex flex-grow-1 justify-content-center'>
              <Button
                className='w-50'
                variant='danger'
                type='submit'
                onClick={handleClose}
              >
                VOLTAR
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

function QuestionsWizard(props) {
  const [wizardSteps, setWizardSteps] = React.useState(
    props.unansweredRequireds
  );
  const {
    showWizard,
    setShowWizard,
    orderUuid,
    addToCart,
    unansweredRequireds,
  } = props;

  React.useEffect(() => {
    if (!showWizard) {
      setWizardSteps(unansweredRequireds);
    }
  }, [setWizardSteps, unansweredRequireds, showWizard]);

  const handleClose = () => setShowWizard(false);

  return (
    <Modal show={showWizard} onHide={handleClose}>
      <Wizard>
        <Steps>
          {wizardSteps.map((step, index) => (
            <Step
              key={step.id}
              id={`${step.id}`}
              render={({ next, previous }) => (
                <>
                  <QuestionCard
                    question={step}
                    orderUuid={orderUuid}
                    isAvailableToOrder={true}
                  />
                  <Modal.Footer
                    className={`d-flex flex-row align-items-center ${
                      index > 0 ? "justify-content-between" : ""
                    }`}
                  >
                    <StepsButton
                      question={step}
                      numberOfSteps={wizardSteps.length}
                      currentStep={index}
                      next={next}
                      previous={previous}
                      addToCart={addToCart}
                      unansweredRequireds={unansweredRequireds}
                    />
                  </Modal.Footer>
                </>
              )}
            />
          ))}
        </Steps>
      </Wizard>
    </Modal>
  );
}

function StepsButton({ numberOfSteps, currentStep, ...props }) {
  const { question, next, previous, addToCart, unansweredRequireds } = props;

  const disabled = unansweredRequireds.map((q) => q.id).includes(question.id);
  const last = currentStep === numberOfSteps - 1;

  return (
    <>
      {currentStep > 0 && (
        <Button variant='outline-danger' onClick={previous}>
          Anterior
        </Button>
      )}
      <Button
        variant='danger'
        onClick={last ? addToCart : next}
        disabled={disabled}
      >
        {last ? "Adicionar" : "Próximo"}
      </Button>
    </>
  );
}
