import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Decimal from "decimal.js";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import ListGroup from "react-bootstrap/ListGroup";
import useToast from "../hooks/useToast";
import useGoTo from "../hooks/useGoTo";
import useWorkingTime from "../hooks/useWorkingTime";
import useMPSecurity from "../hooks/useMPSecurity";
import CheckoutCosts from "./CheckoutCosts";
import CheckoutServerError from "./CheckoutServerError";
import {
  PhoneInput,
  CPFCNPJInput,
  CreditCardCVVInput,
} from "../components/NumberInput";
import { clearCart } from "../ducks/shoppingCartSlice";
import toPriceDisplay from "../utils/toPriceDisplay";
import {
  setFormAnswer,
  setIdentification,
  submitCheckout,
  selectStatefulCosts,
  selectStateSubmitValidations,
  clearCheckout,
  PIX_OPTION,
} from "../ducks/checkoutSlice";
import PaymentCardImage from "../components/PaymentCardImage";
import { validateEmail, validatePhoneNumber } from "../utils/validate";
import { OrderSchedulerConfirmation } from "./OrderScheduler";

const styles = {
  input: {
    marginBottom: 20,
  },
  cvv: {
    marginTop: 40,
    marginBottom: 5,
  },
  cvvAlert: {
    marginTop: 5,
    marginBottom: 40,
  },
  switch: {
    margin: 0,
    marginBottom: 20,
    paddingTop: 9,
    paddingBottom: 9,
  },
  whatsappButton: {
    color: "#fff !important",
    background: "#01e675",
    borderColor: "#01e675",
  },
};

export default function CheckoutForm() {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const [isFidelityVisible, setFidelityVisible] = React.useState(false);
  const [isFormVisible, setFormVisible] = React.useState(false);
  const [isCVVVisible, setCVVVisible] = React.useState(false);
  const [cardCVV, setCardCVV] = React.useState("");
  const shop = useSelector((state) => state.shop);
  const costs = useSelector(selectStatefulCosts);
  const workingTime = useWorkingTime(shop);
  const submitValidation = useSelector(
    selectStateSubmitValidations(workingTime)
  );
  const { toastError } = useToast();
  const {
    isInLoco,
    inLocoCmd,
    inLocoRequireToken,
    token,
    mercadoPagoKey,
    isWhatsappSendingEnabled,
  } = shop;
  useMPSecurity(mercadoPagoKey, "");

  const {
    email,
    name,
    telephone,
    inLocoLocation,
    payment,
    toDelivery,
    paymentOffline,
    paymentOnline,
    identification,
    isIdentificationEnable,
    identificationError,
    isSubmitting,
    cardSelected,
    cardCVV: savedCardCVV,
  } = useSelector((state) => state.checkout);

  const {
    shop: { fidelity },
    user: { userId, userUuid },
  } = useSelector((state) => state);
  const fidelityLogoff = fidelity && (!userId || !userUuid);
  const fidelityAboveMinimal =
    fidelity && Decimal(costs.total).lessThan(fidelity.minimalPurchase);
  const fidelityDiff = fidelityAboveMinimal
    ? Decimal(fidelity.minimalPurchase).minus(costs.total)
    : null;
  const showFidelityModal =
    !costs.fidelityDiscount && (fidelityLogoff || fidelityAboveMinimal);

  const isInLocoWithoutToken = isInLoco && inLocoRequireToken && !token;

  const isSubmitDisabled = submitValidation.disable;

  const onEmailChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "email",
        value: event.target.value,
      })
    );

  const onNameChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "name",
        value: event.target.value,
      })
    );

  const onIdentificationEnableChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "isIdentificationEnable",
        value: event.target.checked,
      })
    );
  const onIdentificationChange = (event) =>
    dispatch(setIdentification(event.target.value));

  const onInLocoLocationChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "inLocoLocation",
        value: event.target.value,
      })
    );

  const onTelephoneChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "telephone",
        value: event.target.value,
      })
    );

  const onCardCVVChange = (event) => setCardCVV(event.target.value);

  const emailError =
    cardSelected && !validateEmail(email) ? "Email inválido" : "";

  const nameError =
    name.length > 0 && name.length < 4
      ? "Nome com no mínimo 4 caracteres"
      : null;

  const phoneError = validatePhoneNumber(telephone) ? "" : "Celular inválido";

  const cvvErrorMessage = cardSelected
    ? `Digite o código de segurança de ${cardSelected.security_code_length} dígitos que está escrito no verso do seu cartão.`
    : "";

  const cvvError =
    cardSelected && cardCVV.length !== cardSelected.security_code_length
      ? cvvErrorMessage
      : "";

  const identificationRequired =
    !isInLoco && paymentOnline && payment !== PIX_OPTION.name;

  const sendWhatsapp = !isInLoco && isWhatsappSendingEnabled && paymentOffline;

  const cancelForm = () => setFormVisible(false);

  const showForm = (event) => {
    event.preventDefault();
    if (submitValidation.paymentRequired) {
      dispatch(setFormAnswer({ name: "isPaymentListVisible", value: true }));
    } else if (toDelivery && costs.deliveryError) {
      toastError(costs.deliveryError, { autoClose: 6000 });
    } else if (costs.couponError) {
      toastError(costs.couponError, { autoClose: 6000 });
    } else if (costs.paymentError) {
      toastError(costs.paymentError, { autoClose: 6000 });
    } else if (showFidelityModal) {
      setFidelityVisible(true);
    } else {
      setFormVisible(true);
    }
  };

  const onSubmitCVV = (event) => {
    event.preventDefault();

    if (cvvError) {
      toastError(cvvError, { autoClose: 6000 });
    } else {
      dispatchSubmitCheckout(cardCVV);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (emailError) {
      toastError(emailError, { autoClose: 6000 });
    } else if (nameError) {
      toastError(nameError, { autoClose: 6000 });
    } else if (phoneError) {
      toastError(phoneError, { autoClose: 6000 });
    } else if (identificationError) {
      toastError(identificationError, { autoClose: 6000 });
    } else if (cardSelected && !savedCardCVV) {
      setCardCVV("");
      setFormVisible(false);
      setCVVVisible(true);
    } else {
      dispatchSubmitCheckout();
    }
  };

  function dispatchSubmitCheckout(cvv) {
    const windowReference = sendWhatsapp ? window.open() : null;
    dispatch(submitCheckout({ cvv }))
      .then(({ shortReference, whatsappURI }) => {
        if (windowReference && whatsappURI) {
          windowReference.location = whatsappURI;
        }
        dispatch(clearCart());
        dispatch(clearCheckout());
        goTo("/");
        goTo(`/status/${shortReference}`);
      })
      .catch((err) => {
        if (windowReference) {
          windowReference.close();
        }
        setFormVisible(false);
        if (!err.isUserReadable) {
          toastError("Erro desconhecido tentar enviar pedido.", {
            autoClose: 6000,
          });
        }
      });
  }

  const cancelFidelity = () => setFidelityVisible(false);
  const redirectToProductsPage = () => goTo("/");
  const redirectToFidelityPage = () => goTo("/fidelity");
  const continueWithoutFidelity = () => {
    setFidelityVisible(false);
    setFormVisible(true);
  };

  const cancelCVV = () => setCVVVisible(false);

  return (
    <div className="p-3">
      <CheckoutCosts />
      <Button
        variant="danger"
        className="w-100 p-2"
        disabled={isSubmitDisabled}
        onClick={showForm}
      >
        <span>{submitValidation.message}</span>
      </Button>

      <Modal show={isFormVisible} onHide={cancelForm}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Finalizar</Modal.Title>
        </Modal.Header>

        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {identificationRequired && (
              <TextField
                fullWidth={true}
                style={styles.input}
                variant="outlined"
                type="text"
                label="Email"
                error={emailError ? true : false}
                helperText={emailError}
                value={email}
                onChange={onEmailChange}
                maxLength={64}
                required
              />
            )}
            <TextField
              fullWidth={true}
              style={styles.input}
              variant="outlined"
              type="text"
              label="Nome"
              error={nameError ? true : false}
              helperText={nameError}
              value={name}
              onChange={onNameChange}
              maxLength={64}
              required
            />
            <TextField
              fullWidth={true}
              style={styles.input}
              variant="outlined"
              label="Celular com DDD"
              error={phoneError ? true : false}
              helperText={phoneError}
              value={telephone}
              onChange={onTelephoneChange}
              InputProps={{
                inputComponent: PhoneInput,
              }}
              required
            />
            {identificationRequired && (
              <TextField
                fullWidth={true}
                style={styles.input}
                error={identificationError ? true : false}
                helperText={identificationError}
                variant="outlined"
                label="CPF ou CNPJ"
                value={identification}
                onChange={onIdentificationChange}
                required
                InputProps={{
                  inputComponent: CPFCNPJInput,
                }}
              />
            )}
            {!isInLoco &&
              !identificationRequired &&
              !isIdentificationEnable && (
                <FormControlLabel
                  control={<Switch onChange={onIdentificationEnableChange} />}
                  label="CPF ou CNPJ na nota?"
                  style={styles.switch}
                />
              )}
            {!isInLoco && !identificationRequired && isIdentificationEnable && (
              <TextField
                fullWidth={true}
                style={styles.input}
                error={identificationError ? true : false}
                helperText={identificationError}
                variant="outlined"
                label="CPF ou CNPJ"
                value={identification}
                onChange={onIdentificationChange}
                InputProps={{
                  inputComponent: CPFCNPJInput,
                  startAdornment: (
                    <Switch
                      checked={isIdentificationEnable}
                      onChange={onIdentificationEnableChange}
                    />
                  ),
                }}
                required
              />
            )}
            {isInLoco && inLocoCmd && (
              <TextField
                fullWidth={true}
                style={styles.input}
                // error={identificationError ? true : false}
                helperText="Ex: Mesa 12 ou Balcão"
                variant="outlined"
                label="Local de entrega"
                value={inLocoLocation}
                onChange={onInLocoLocationChange}
                required
                inputProps={{ maxLength: 30 }}
              />
            )}
            {isInLocoWithoutToken && (
              <p className="text-danger m-5">
                Antes de enviar o primeiro pedido peça para um atendente liberar
                o cardápio digital. O atendente irá trazer um QR Code para você
                escanear.
              </p>
            )}

            {submitValidation.isScheduledOrder && (
              <OrderSchedulerConfirmation />
            )}

            {sendWhatsapp && (
              <Button
                type="submit"
                className="w-100 d-flex align-items-center justify-content-between p-3"
                style={styles.whatsappButton}
                disabled={isSubmitDisabled || isInLocoWithoutToken}
              >
                <span>
                  <WhatsAppIcon className="mr-2" />
                  <strong>Enviar no WhatsApp</strong>
                </span>
                {isSubmitting && (
                  <Spinner animation="border" size="sm" className="mr-2" />
                )}
                <span>{toPriceDisplay(costs.total)}</span>
              </Button>
            )}

            {!sendWhatsapp && (
              <Button
                type="submit"
                variant="danger"
                className="w-100 d-flex align-items-center justify-content-between p-3"
                disabled={isSubmitDisabled || isInLocoWithoutToken}
              >
                Enviar pedido
                {isSubmitting && (
                  <Spinner animation="border" size="sm" className="mr-2" />
                )}
                <span>{toPriceDisplay(costs.total)}</span>
              </Button>
            )}
          </Modal.Body>
        </Form>
      </Modal>

      <Modal show={isFidelityVisible} onHide={cancelFidelity}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Quer um desconto?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fidelityLogoff && (
            <p>Faça login para participar do Programa de Fidelidade!</p>
          )}

          {fidelityAboveMinimal && (
            <p>{`Com mais ${toPriceDisplay(
              fidelityDiff
            )} em produtos esse pedido será válido para o programa de fidelidade`}</p>
          )}

          {fidelityLogoff && (
            <Button
              variant="danger"
              className="w-100 d-flex align-items-center justify-content-between p-3 mb-3"
              onClick={redirectToFidelityPage}
            >
              Participar do fidelidade
            </Button>
          )}

          {!fidelityLogoff && fidelityAboveMinimal && (
            <Button
              variant="danger"
              className="w-100 d-flex align-items-center justify-content-between p-3 mb-3"
              onClick={redirectToProductsPage}
            >
              Adicionar mais produtos
            </Button>
          )}

          <Button
            variant="outline-danger"
            className="w-100 d-flex center justify-content-between p-3"
            onClick={continueWithoutFidelity}
          >
            Continuar sem fidelidade
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={isCVVVisible} onHide={cancelCVV}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Confirme o pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cardSelected && (
            <ListGroup.Item className="d-flex flex-row align-items-center">
              <PaymentCardImage
                paymentLabel={cardSelected.issuer_name}
                className="mr-4"
              />
              <div>
                {cardSelected.issuer_name}
                <br />
                {`**** ${cardSelected.last_four_digits}`}
              </div>
            </ListGroup.Item>
          )}

          <TextField
            fullWidth={true}
            style={styles.cvv}
            variant="outlined"
            label="CVV"
            value={cardCVV}
            onChange={onCardCVVChange}
            InputProps={{
              inputComponent: CreditCardCVVInput,
            }}
            required
          />

          <Alert style={styles.cvvAlert} variant="info" className="text-center">
            <small className="text-muted">{cvvErrorMessage}</small>
          </Alert>

          <Button
            variant="danger"
            className="w-100 d-flex align-items-center justify-content-between p-3"
            onClick={onSubmitCVV}
          >
            Confirmar
            {isSubmitting && (
              <Spinner animation="border" size="sm" className="mr-2" />
            )}
            <span>{toPriceDisplay(costs.total)}</span>
          </Button>
        </Modal.Body>
      </Modal>

      <CheckoutServerError />
    </div>
  );
}
