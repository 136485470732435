import React from "react";
import OrderFollowup from "../containers/OrderFollowup";

export default function OrderStatusScreen() {
  // const { isSelfchekout } = useSelector((state) => state.shop);

  // if (isSelfchekout) {
  //   return <SelfCheckoutPayment />;
  // }

  return <OrderFollowup />;
}
