import React from "react";
import { Container, Spinner, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Typography } from "@material-ui/core";
import useGoTo from "../../hooks/useGoTo";
import { STATUS_DETAILS } from "../../ducks/consts";
import Loading from "../../components/Loading";

const useStyles = makeStyles(() => ({
  divPagamentoSucesso: {
    background: `green`,
    padding: `1rem`,
    color: `white`,
    borderRadius: `1rem`,
  },
  divEstorno: {
    background: `red`,
    padding: `1rem`,
    color: `white`,
    borderRadius: `1rem`,
  },
  icon: {
    width: 120,
    height: 120,
    marginBottom: `1rem`,
  },
  spinner: {
    width: "8rem",
    height: "8rem",
    marginLeft: `auto`,
    marginRight: `auto`,
    marginBottom: "3rem",
    marginTop: "3rem",
  },
}));

export default function SelfCheckoutTotemStatus({
  shortReference,
  order,
  status,
}) {
  const goto = useGoTo();
  const classes = useStyles();
  const [seconds, setSeconds] = React.useState(30);

  const paid = status
    ? status.some((s) => s.code === STATUS_DETAILS.PAYMENT.code)
    : false;

  const cancelled = status
    ? status.some((s) => s.code === STATUS_DETAILS.CANCELLED.code)
    : false;

  React.useEffect(() => {
    if (paid || cancelled) {
      const id = setInterval(() => setSeconds((v) => v - 1), 1000);
      return () => clearInterval(id);
    }
  }, [paid, cancelled]);

  if (!order || !status) {
    return (
      <Container>
        <Loading message="Carregando Status Autoatendimento Totem..." />
      </Container>
    );
  }

  const handleNovoPedido = () => {
    goto("/");
  };

  if (seconds <= 0) {
    handleNovoPedido();
  }

  return (
    <Container className={`text-center`}>
      {!cancelled && !paid && (
        <div className={`d-flex flex-column`}>
          <Typography variant="h3" className="mt-5 p-5">
            <strong>{`Pagamento`}</strong>
          </Typography>
          <Spinner animation="border" className={classes.spinner} />
          <Typography variant="h3" className="p-5">
            Para realizar o pagamento siga as instruçoes na maquininha ao lado
          </Typography>
        </div>
      )}
      {!cancelled && paid && (
        <div>
          <div className="d-flex flex-row justify-content-center m-5">
            <Typography variant="h3" className="m-5">
              <strong>{`Seu pedido #${shortReference} foi pago com sucesso!`}</strong>
            </Typography>
          </div>
          <div
            className={`d-flex flex-column align-items-center m-5 p-5 ${classes.divPagamentoSucesso}`}
          >
            <CheckCircleIcon className={classes.icon} />
            <Typography variant="h4">
              <strong>
                Vamos iniciar o preparo do seu pedido e quanto estiver pronto
                chamaremos pelo número #{shortReference}
              </strong>
            </Typography>
          </div>
          <Button className="mt-5 px-5 py-3" onClick={handleNovoPedido}>
            <Typography variant="h5">Novo Pedido ({seconds})</Typography>
          </Button>
        </div>
      )}
      {cancelled && (
        <div>
          <Typography variant="h3" className="mt-5 p-5">
            <strong>{`Pagamento`}</strong>
          </Typography>
          <div
            className={`d-flex flex-column align-items-center m-5 p-5  ${classes.divEstorno}`}
          >
            <CancelIcon className={classes.icon} />
            <Typography variant="h4">
              <strong>O pagamento foi cancelado.</strong>
            </Typography>
          </div>
          <Button className="mt-5 px-5 py-3" onClick={handleNovoPedido}>
            <Typography variant="h5">Novo Pedido ({seconds})</Typography>
          </Button>
        </div>
      )}
    </Container>
  );
}
