import { createSlice } from '@reduxjs/toolkit';
import assignPick from '../utils/assignPick';
import { STATE_STEP, FIREBASE_BASE_URL } from './consts';
import { parseProductQuestions } from '../utils/firebase-adapters';
import { minimalDelayedHOC } from '../utils/minimalDelayedHOC';

const initialState = Object.freeze({
  groups: [],
  items: [],
  questionsByProduct: {},
  questionsStateByProduct: {},
});

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setGroupedProductsWithQuestions: (state, { payload }) => {
      assignPick(state, payload, ['groups', 'items']);
      payload.items.forEach((product) => {
        state.questionsByProduct[product.id] = [];
        state.questionsStateByProduct[product.id] = STATE_STEP.INITIAL;
      });
    },
    setLoading: (state, { payload }) => {
      state.questionsStateByProduct[payload.productId] = STATE_STEP.LOADING;
    },
    setReady: (state, { payload }) => {
      state.questionsByProduct[payload.productId] = payload.questions;
      state.questionsStateByProduct[payload.productId] = STATE_STEP.READY;
    },
    setError: (state, { payload }) => {
      state.questionsStateByProduct[payload.productId] = STATE_STEP.ERROR;
    }
  },
});

export const { setGroupedProductsWithQuestions } = productsSlice.actions;

export const requireProducts = (productId) => async (dispatch, getState) => {

  const {
    products: { questionsStateByProduct, items },
    appState: { namespace },
  } = getState();

  const product = items.find(p => p.id === productId);
  if (!product) {
    return;
  }

  const stateStep = questionsStateByProduct[productId];
  if (stateStep === STATE_STEP.LOADING || stateStep === STATE_STEP.READY) {
    return;
  }

  const baseUrl = `${FIREBASE_BASE_URL}/${namespace}/perguntas`;
  const questionsIds = product.questionsIds || [];

  dispatch(productsSlice.actions.setLoading({ productId }));

  const calmDispatch = minimalDelayedHOC(dispatch);
  await Promise.all(questionsIds.map(questionId => fetch(`${baseUrl}/${questionId}.json`)))
    .then(responses => Promise.all(responses.map(r => r.json())))
    .then(parseProductQuestions)
    .then(questions => calmDispatch(productsSlice.actions.setReady({ productId, questions })))
    .catch(() => calmDispatch(productsSlice.actions.setError({ productId })));
};

export default productsSlice.reducer;
