import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import Figure from "react-bootstrap/Figure";
import { useSelector } from "react-redux";
import useWorkingTime from "../hooks/useWorkingTime";
import ShopHeader from "../containers/ShopHeader";
import ShopFooter from "../containers/ShopFooter";
import Avatar from "../components/Avatar";

export default function MarketplaceScreen() {
  return (
    <main>
      <ShopHeader />
      <MarketplaceShopsList />
      <ShopFooter />
    </main>
  );
}

function MarketplaceShopsList() {
  const shops = useSelector((state) => state.marketplace.shops);

  return (
    <div>
      <h2 className='text-danger font-weight-bold pl-3'>Restaurantes</h2>
      <p className='mb-4 pl-3'>
        Escolha um restaurante da lista para ver o cardápio completo.
      </p>
      {shops.length === 0 && (
        <Alert variant='secondary' className='text-center'>
          Nenhum restaurante encontrado na lista.
        </Alert>
      )}
      <ListGroup>
        {shops.map((shop) => (
          <MarketplaceShopsListItem key={shop.uri} shop={shop} />
        ))}
      </ListGroup>
    </div>
  );
}

function MarketplaceShopsListItem({ shop }) {
  const { isWorking } = useWorkingTime(shop);

  const handleClick = () => {
    window.location = `${window.location.origin}/${shop.uri}`;
  };

  return (
    <ListGroup.Item
      className='cursor-pointer borderless'
      key={shop.uri}
      onClick={handleClick}
    >
      <Row>
        <Col
          xs='4'
          sm='3'
          lg='2'
          className='d-flex flex-column justify-content-center'
        >
          {shop.pictureUrl && (
            <Figure.Image
              src={shop.pictureUrl}
              alt='Logo da loja'
              fluid={false}
              className='w-100'
              rounded
            />
          )}
          {!shop.pictureUrl && <Avatar name={shop.name} />}
        </Col>
        <Col xs={8} sm={9} lg={10}>
          <p className='mt-3'>{shop.name}</p>
          <p className='text-muted'>
            {isWorking ? (
              <small className='text-success font-weight-bold'>Aberto</small>
            ) : (
              <small className='font-weight-bold'>Fechado</small>
            )}
          </p>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
