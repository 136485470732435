import get from "lodash.get";
import last from "lodash.last";
import sortBy from "lodash.sortby";
import { createSlice } from "@reduxjs/toolkit";
import Decimal from "decimal.js";
import { isValid as isValidCPF } from "cpf";
import { validate as isValidCNPJ } from "cnpj";
import CryptoJS from "crypto-js";
import { minimalDelayedHOC } from "../utils/minimalDelayedHOC";
import pickT from "../utils/pickT";
import toPriceDisplay from "../utils/toPriceDisplay";
import { API_PALLAS_APPS_URL } from "./consts";
import CheckoutSubmission from "../services/checkout-submission";

const HOURS_TO_EXPIRE_CACHE = 1;

const initialFormAnswers = Object.freeze({
  email: "",
  name: "",
  telephone: "",
  coupon: "",
  inLocoLocation: "",
  toGo: false,
  toDelivery: false,
  deliveryAddress: {
    addressStreet: "",
    addressNumber: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    postalCode: "",

    house: false,
    apartment: false,
    apartmentNumber: "",
    apartmentBlock: "",
    reference: "",
  },

  paymentOnline: true,
  paymentOffline: false,
  isPaymentListVisible: false,
  isCashFormVisible: false,
  isCardFormVisible: false,
  payment: "",
  cash: "0.00",
  cardSelected: null,
  cardCVV: "",

  identification: "",
  isIdentificationEnable: false,
  cards: [],
  cachedAt: Date.now(),
});

const initialLocationInfo = Object.freeze({
  isSearchingLocation: false,
  locationError: "",
  searchedDelivery: { distance: null, fee: null },
  isEqualDeliveryFee: false,
  lastSearchedDelivery: null,
});

const initialIdentificationInfo = Object.freeze({
  identificationError: "",
});

const initialCouponInfo = Object.freeze({
  isSearchingCoupon: false,
  isValidCoupon: false,
  couponAbsoluteDiscount: "0.00",
  couponPercentageDiscount: "0.00",
  couponMinimalSubtotal: "0.00",
  couponError: "",
});

const initialSubmitState = Object.freeze({
  isSubmitting: false,
  isErrorVisible: false,
  submitError: null,
  lastCheckoutCache: null,
});

const initialInLocoState = Object.freeze({
  isTakingHome: false,
});

const initialOrderSchedulingState = Object.freeze({
  orderScheduling: {
    isScheduledOrder: false,
    schedule: null,
  },
});

const initialState = Object.freeze({
  ...initialFormAnswers,
  ...initialLocationInfo,
  ...initialIdentificationInfo,
  ...initialCouponInfo,
  ...initialSubmitState,
  ...initialInLocoState,
  ...initialOrderSchedulingState,
});

const encryptWithCryptoJS = (plainText) => {
  const key = CryptoJS.enc.Utf8.parse("xt3213yqwenf738h");
  const iv1 = CryptoJS.enc.Utf8.parse("xt3213yqwenf738h");
  const encrypted = CryptoJS.AES.encrypt(plainText, key, {
    keySize: 16,
    iv: iv1,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return encrypted + "";
};

const decryptionWithCryptoJS = (cipher) => {
  const key = CryptoJS.enc.Utf8.parse("xt3213yqwenf738h");
  const iv1 = CryptoJS.enc.Utf8.parse("xt3213yqwenf738h");
  const plainText = CryptoJS.AES.decrypt(cipher, key, {
    keySize: 16,
    iv: iv1,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return plainText.toString(CryptoJS.enc.Utf8);
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setPaymentOnline: (state) => {
      state.paymentOnline = true;
      state.paymentOffline = false;
    },
    setPaymentOffline: (state) => {
      state.paymentOffline = true;
      state.paymentOnline = false;
    },
    selectCard: (state, { payload }) => {
      state.cardSelected = payload;
      state.cardCVV = "";
      state.isPaymentListVisible = false;
      state.payment = `${payload.issuer_name} **** ${payload.last_four_digits}`;
    },
    removeCard: (state, { payload }) => {
      state.cards = state.cards.filter((c) => c.id !== payload.id);
    },
    addCard: (state, { payload }) => {
      const { card, token, issuerList } = payload;
      const cvv = card.securityCode;
      delete card.securityCode;
      const hash = encryptWithCryptoJS(JSON.stringify(card)).toString();
      const newCard = {
        id: token.id,
        last_four_digits: token.last_four_digits,
        security_code_length: token.security_code_length,
        issuer_name: issuerList.length > 0 ? issuerList[0].name : "",
        issuer_thumbnail: issuerList.length > 0 ? issuerList[0].thumbnail : "",
        token: hash,
      };

      state.cards = [...state.cards, newCard];
      state.cardSelected = newCard;
      state.cardCVV = cvv;
      state.isPaymentListVisible = false;
      state.isCardFormVisible = false;
      state.payment = `${newCard.issuer_name} **** ${newCard.last_four_digits}`;
    },
    clearPayment: (state) => {
      state.isPaymentListVisible = initialFormAnswers.isPaymentListVisible;
      state.isCashFormVisible = initialFormAnswers.isCashFormVisible;
      state.isCardFormVisible = initialFormAnswers.isCardFormVisible;

      state.payment = initialFormAnswers.payment;
      state.cash = initialFormAnswers.cash;
      state.cardSelected = initialFormAnswers.cardSelected;
      state.cardCVV = initialFormAnswers.cardCVV;
    },
    setPayment: (state, { payload }) => {
      state.isPaymentListVisible = false;
      state.payment = payload.name;
      state.isCashFormVisible = payload.name === CASH_OPTION.name;
      state.cash = initialFormAnswers.cash;

      state.isIdentificationEnable =
        state.isIdentificationEnable || payload.name === PIX_OPTION.name;

      state.cardSelected = initialFormAnswers.cardSelected;
      state.cardCVV = initialFormAnswers.cardCVV;
    },
    setCash: (state, { payload }) => {
      state.isPaymentListVisible = false;
      state.isCashFormVisible = false;
      state.cash = payload.cash;
    },

    setToGo: (state) => {
      state.toGo = true;
      state.toDelivery = false;
    },
    setToDelivery: (state) => {
      state.toGo = false;
      state.toDelivery = true;
    },

    setInLocoAnswer: (state, { payload }) => {
      const { name, value } = pickT(payload, "name", "value");
      const isValidName = Object.keys(initialInLocoState).includes(name);
      const isValidValue = typeof state[name] === typeof value;
      if (!isValidName || !isValidValue) {
        return;
      }

      state[name] = value;
    },
    setFormAnswer: (state, { payload }) => {
      const { name, value } = pickT(payload, "name", "value");
      const isValidName = Object.keys(initialFormAnswers).includes(name);
      const isValidValue = typeof state[name] === typeof value;
      if (!isValidName || !isValidValue) {
        return;
      }

      state[name] = value;
    },
    setIdentification: (state, { payload }) => {
      const identification = payload || "";
      const content = identification.trim().replace(rAlphabetics, "");
      state.identification = content;
      if (content.length === 0 || isValidCPF(content) || isValidCNPJ(content)) {
        state.identificationError = "";
      } else {
        state.identificationError = "CPF / CNPJ inválido";
      }
    },
    setDeliveryAddress: (state, { payload }) => {
      if (state.isSearchingLocation) {
        return;
      }

      const equalDeliveryFee =
        !state.locationError &&
        state.searchedDelivery.fee &&
        state.deliveryAddress.addressStreet === payload.addressStreet &&
        state.deliveryAddress.neighborhood === payload.neighborhood &&
        state.deliveryAddress.addressNumber === payload.addressNumber;

      state.isEqualDeliveryFee = equalDeliveryFee;
      state.lastSearchedDelivery = equalDeliveryFee
        ? state.searchedDelivery
        : null;

      state.deliveryAddress = payload;
      state.locationError = null;
      state.searchedDelivery = initialLocationInfo.searchedDelivery;
    },
    setLocationSearchLoading: (state) => {
      state.isSearchingLocation = true;
      state.locationError = null;
      state.searchedDelivery = initialLocationInfo.searchedDelivery;
    },
    setLocationSearchDone: (state, { payload }) => {
      const { distance, fee } = payload;
      state.isSearchingLocation = false;
      state.locationError = initialLocationInfo.locationError;
      state.searchedDelivery = { distance, fee };
    },
    setLocationSearchError: (state) => {
      state.isSearchingLocation = false;
      state.locationError = "Serviço de cálculo de entrega indisponível.";
      state.searchedDelivery = initialLocationInfo.searchedDelivery;
    },
    setCouponSearchLoading: (state) => {
      state.isSearchingCoupon = true;
      state.isValidCoupon = false;
      state.couponError = "";
      state.couponAbsoluteDiscount = initialCouponInfo.couponAbsoluteDiscount;
      state.couponPercentageDiscount =
        initialCouponInfo.couponPercentageDiscount;
      state.couponMinimalSubtotal = initialCouponInfo.couponMinimalSubtotal;
    },
    setCouponSearchDone: (state, { payload }) => {
      const {
        isValidCoupon,
        couponError,
        couponAbsoluteDiscount,
        couponPercentageDiscount,
        couponMinimalSubtotal,
      } = payload;
      state.isSearchingCoupon = false;
      state.isValidCoupon = isValidCoupon;
      state.couponError = isValidCoupon
        ? ""
        : couponError || "Não foi possível validar este cupom.";
      state.couponAbsoluteDiscount = isValidCoupon
        ? couponAbsoluteDiscount
        : initialCouponInfo.couponAbsoluteDiscount;
      state.couponPercentageDiscount = isValidCoupon
        ? couponPercentageDiscount
        : initialCouponInfo.couponPercentageDiscount;
      state.couponMinimalSubtotal = isValidCoupon
        ? couponMinimalSubtotal
        : initialCouponInfo.couponMinimalSubtotal;
    },
    setCouponRemove: (state) => {
      state.coupon = initialFormAnswers.coupon;
      state.isSearchingCoupon = initialCouponInfo.isSearchingCoupon;
      state.isValidCoupon = initialCouponInfo.isValidCoupon;
      state.couponError = initialCouponInfo.couponError;
      state.couponAbsoluteDiscount = initialCouponInfo.couponAbsoluteDiscount;
      state.couponPercentageDiscount =
        initialCouponInfo.couponPercentageDiscount;
      state.couponMinimalSubtotal = initialCouponInfo.couponMinimalSubtotal;
    },
    setSubmitLoading: (state) => {
      state.isSubmitting = true;
      state.submitError = null;
    },
    setSubmitError: (state, { payload }) => {
      state.submitError = payload;
      state.isErrorVisible = true;
    },
    setCloseError: (state) => {
      state.submitError = null;
      state.isErrorVisible = false;
    },
    setSubmitFinished: (state, { payload }) => {
      state.isSubmitting = false;
      if (!payload) {
        return;
      }

      state.lastCheckoutCache = payload.checkoutCache;
      state.submitError = null;
    },
    setCachedAt: (state, { payload }) => {
      state.cachedAt = payload.cachedAt;
    },
    clearCheckout: (state) => {
      state.toGo = initialFormAnswers.toGo;
      state.toDelivery = initialFormAnswers.toDelivery;
      state.coupon = initialFormAnswers.coupon;

      state.paymentOnline = initialFormAnswers.paymentOnline;
      state.paymentOffline = initialFormAnswers.paymentOffline;
      state.payment = initialFormAnswers.payment;
      state.cash = initialFormAnswers.cash;
      state.cardSelected = initialFormAnswers.cardSelected;
      state.cardCVV = initialFormAnswers.cardCVV;

      state.isSearchingCoupon = initialCouponInfo.isSearchingCoupon;
      state.isValidCoupon = initialCouponInfo.isValidCoupon;
      state.couponAbsoluteDiscount = initialCouponInfo.couponAbsoluteDiscount;
      state.couponPercentageDiscount =
        initialCouponInfo.couponPercentageDiscount;
      state.couponMinimalSubtotal = initialCouponInfo.couponMinimalSubtotal;
      state.couponError = initialCouponInfo.couponError;
      state.orderScheduling = initialOrderSchedulingState.orderScheduling;
    },
    clearCheckoutTotem: () => {
      return initialState;
    },
    setOrderScheduling: (state, { payload }) => {
      state.orderScheduling.isScheduledOrder = payload.isScheduledOrder;
      state.orderScheduling.schedule = payload.schedule;
    },
  },
});

export const {
  setPaymentOnline,
  setPaymentOffline,
  setPayment,
  selectCard,
  removeCard,
  addCard,
  clearPayment,
  setCash,

  setFormAnswer,
  setSubmitError,
  setCloseError,
  setIdentification,
  setToGo,
  setToDelivery,
  setDeliveryAddress,
  setCouponRemove,
  clearCheckout,
  clearCheckoutTotem,
  setInLocoAnswer,
  setOrderScheduling,
} = checkoutSlice.actions;

export const searchDeliveryCalc = () => async (dispatch, getState) => {
  const fullState = getState();

  dispatch(checkoutSlice.actions.setLocationSearchLoading());
  const calmDispatch = minimalDelayedHOC(dispatch);

  if (fullState.shop.deliveryBy) {
    deliveryByPartner(calmDispatch, fullState);
  } else {
    // if (await deliveryEqualAddress(calmDispatch, fullState)) {
    //   return;
    // }

    deliveryByShop(calmDispatch, fullState);
  }
};

// const deliveryEqualAddress = async (calmDispatch, fullState) => {
//   if (fullState.checkout.isEqualDeliveryFee) {
//     const last = fullState.checkout.lastSearchedDelivery;
//     if (last) {
//       const newFee = fullState.shop.deliveryFees.find(fee => fee.id === last.fee.id);
//       if (newFee && newFee.enable &&
//           newFee.price === last.fee.price) {
//         await calmDispatch(checkoutSlice.actions.setLocationSearchDone(last));
//         return true;
//       }
//     }
//   }
//   return false;
// }

const deliveryByPartner = async (calmDispatch, fullState) => {
  const data = {
    lojaId: fullState.shop.id,
    deliveryAddress: {
      ...fullState.checkout.deliveryAddress,
    },
  };

  try {
    const url = `${API_PALLAS_APPS_URL}/taxaEntrega`;
    const response = await fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    const { distance, fee } = json;
    await calmDispatch(
      checkoutSlice.actions.setLocationSearchDone({ distance, fee })
    );
  } catch (error) {
    console.log(error);
    await calmDispatch(checkoutSlice.actions.setLocationSearchError());
  }
};

const deliveryByShop = async (calmDispatch, fullState) => {
  const deliveryFees = fullState.shop.deliveryFees;
  const shopAddressLines = [
    fullState.shop.addressStreet,
    fullState.shop.addressNumber,
    fullState.shop.neighborhood,
    fullState.shop.city,
    fullState.shop.state,
    fullState.shop.zip,
  ];
  const deliveryAddressLines = [
    fullState.checkout.deliveryAddress.addressStreet,
    fullState.checkout.deliveryAddress.addressNumber,
    fullState.checkout.deliveryAddress.neighborhood,
    fullState.checkout.deliveryAddress.city,
    fullState.checkout.deliveryAddress.state,
    fullState.checkout.deliveryAddress.postalCode,
  ];

  const neighborhood = fullState.checkout.deliveryAddress.neighborhood;
  const fixedFee = deliveryFees
    .filter((fee) => (fee.neighborhood ? true : false))
    .find(
      (fee) => fee.neighborhood.toLowerCase() === neighborhood.toLowerCase()
    );
  if (fixedFee) {
    await calmDispatch(
      checkoutSlice.actions.setLocationSearchDone({
        distance: {
          text: fixedFee.maxDistanceKms,
        },
        fee: fixedFee,
      })
    );
    return;
  }

  try {
    const params = new URLSearchParams();
    params.set("cnpj", fullState.shop.cnpj);
    params.set("origem", shopAddressLines.join(","));
    params.set("destino", deliveryAddressLines.join(","));
    const url = `${API_PALLAS_APPS_URL}/distancia?${params.toString()}`;
    const response = await fetch(url);
    const distance = await response.json();
    const fee = findDeliveryFee(deliveryFees, distance);
    await calmDispatch(
      checkoutSlice.actions.setLocationSearchDone({ distance, fee })
    );
  } catch (error) {
    console.log(error);
    await calmDispatch(checkoutSlice.actions.setLocationSearchError());
  }
};

export const searchCouponValidation = () => async (dispatch, getState) => {
  const fullState = getState();
  const { id: shopId } = fullState.shop;
  const { coupon } = fullState.checkout;

  dispatch(checkoutSlice.actions.setCouponSearchLoading());

  const calmExecute = minimalDelayedHOC((f) => f());

  const url = `${API_PALLAS_APPS_URL}/promocao?lojaId=${shopId}&cupom=${coupon}`;
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (!data || typeof data !== "object") {
        throw new Error("Very wrong JSON data format.");
      }

      const currentTime = Date.now();
      const parsed = {
        begin: new Date(data["inicio"]),
        end: new Date(data["fim"]),
        couponAbsoluteDiscount: data["descontoValor"],
        couponPercentageDiscount: data["descontoPorcentagem"],
        couponMinimalSubtotal: data["pedidoMinimo"],
      };

      if (data.error && data.error.name === "NotFoundError") {
        calmExecute(() => {
          dispatch(
            checkoutSlice.actions.setCouponSearchDone({
              isValidCoupon: false,
              couponError: "Cupom não encontrado.",
            })
          );
        });
      } else if (data.error) {
        throw new Error(
          data.error.message || "Unknown error payload in response."
        );
      } else if (currentTime < parsed.begin || currentTime > parsed.end) {
        calmExecute(() => {
          dispatch(
            checkoutSlice.actions.setCouponSearchDone({
              isValidCoupon: false,
              couponError: "Cupom fora do prazo de validade.",
            })
          );
        });
      } else {
        calmExecute(() => {
          dispatch(
            checkoutSlice.actions.setCouponSearchDone({
              isValidCoupon: true,
              couponAbsoluteDiscount: parsed.couponAbsoluteDiscount.toString(),
              couponPercentageDiscount:
                parsed.couponPercentageDiscount.toString(),
              couponMinimalSubtotal: parsed.couponMinimalSubtotal.toString(),
            })
          );
        });
      }
    })
    .catch((error) => {
      console.error("Failed to search coupon due to unexpected error", error);
      calmExecute(() => {
        dispatch(
          checkoutSlice.actions.setCouponSearchDone({ isValidCoupon: false })
        );
      });
    });
};

const getMercadoPagoToken = async (fullState, cvv) => {
  if (!fullState.checkout.paymentOnline) {
    return null;
  }

  let token = null;
  const checkout = fullState.checkout;
  if (checkout.cardSelected) {
    const card = JSON.parse(
      decryptionWithCryptoJS(checkout.cardSelected.token)
    );
    card.securityCode = checkout.cardCVV || cvv;
    token = await window.mercadoPago.createCardToken(card);
  } else {
    token = {
      id: checkout.payment,
    };
  }

  const deviceIdElement = document.getElementById("deviceId");
  token.device_id = deviceIdElement.value;
  token.public_key = fullState.shop.mercadoPagoKey;
  return token;
};

export const submitCheckout =
  ({ cvv }) =>
  async (dispatch, getState) => {
    const fullState = getState();
    const costs = selectStatefulCosts(fullState);

    const mercadoPagoToken = await getMercadoPagoToken(fullState, cvv);

    const productOrders = fullState.shoppingCart.productOrders
      .filter((o) => o.commited)
      .map((o) => ({
        ...o,
        answers: fullState.shoppingCart.answersByOrder[o.uuid],
        cachedCost: fullState.shoppingCart.cachedCostPerOrder[o.uuid],
      }));

    const submittingData = {
      shopId: fullState.shop.id,
      shopUri: fullState.shop.uri,
      isTotem: fullState.shop.isSelfchekout,
      isInLoco: fullState.shop.isInLoco,
      tableId: fullState.shop.tableId,
      inLocoToken: fullState.shop.token,
      productOrders,
      checkout: {
        appUserId: fullState.shop.fidelity ? fullState.user.userId : null,
        appUserUuid: fullState.shop.fidelity ? fullState.user.userUuid : null,
        inLocoLocation: fullState.checkout.inLocoLocation,
        isTakingHome: fullState.checkout.isTakingHome,
        toGo: fullState.checkout.toGo,
        toDelivery: fullState.checkout.toDelivery,
        email: fullState.checkout.email,
        name: fullState.checkout.name,
        telephone: fullState.checkout.telephone,
        payment: fullState.checkout.payment,
        changeFor: fullState.checkout.cash,
        identification: fullState.checkout.isIdentificationEnable
          ? fullState.checkout.identification
          : null,
        deliveryAddress: costs.hasFees
          ? fullState.checkout.deliveryAddress
          : null,
        deliveryFee: costs.hasFees
          ? fullState.checkout.searchedDelivery.fee.id
          : null,
        coupon: costs.hasValidCoupon ? fullState.checkout.coupon : null,
        _unsafe_costs: {
          subtotal: costs.subtotal,
          delivery: costs.delivery,
          discount: costs.discount,
          takeoutDiscount: costs.takeoutDiscount,
          total: costs.total,
          paymentDiff: costs.paymentDiff,
          fidelityDiscount: costs.fidelityDiscount,
        },
        mercadoPagoToken,
        orderScheduling: fullState.checkout.orderScheduling,
      },
    };

    dispatch(checkoutSlice.actions.setSubmitLoading());

    const calmDispatch = minimalDelayedHOC(dispatch);
    try {
      const pallasResponseData = await CheckoutSubmission.sendPallas(
        submittingData,
        dispatch
      );

      const checkoutCache = parseDataForCache(
        pallasResponseData,
        submittingData.productOrders,
        costs,
        fullState
      );

      const { whatsapp, isWhatsappSendingEnabled } = fullState.shop;
      const whatsappURI =
        isWhatsappSendingEnabled && fullState.checkout.paymentOffline
          ? CheckoutSubmission.sendWhatsApp(whatsapp, checkoutCache)
          : null;

      await calmDispatch(
        checkoutSlice.actions.setSubmitFinished({
          checkoutCache,
        })
      );

      return {
        id: pallasResponseData.id,
        shortReference: pallasResponseData.shortReference,
        whatsappURI,
      };
    } catch (error) {
      console.error(error);
      await calmDispatch(checkoutSlice.actions.setSubmitFinished());
      throw error;
    }
  };

export const validateCache = () => (dispatch, getState) => {
  const fullState = getState();
  const isInLoco = fullState.shop.isInLoco;
  const { toGo, toDelivery, cachedAt } = fullState.checkout;

  if (isInLoco && (toGo || toDelivery)) {
    dispatch(checkoutSlice.actions.clearCheckout());
    dispatch(checkoutSlice.actions.setCachedAt({ cachedAt: Date.now() }));
    return;
  }

  const expiringCacheTimestamp =
    cachedAt + HOURS_TO_EXPIRE_CACHE * 60 * 60 * 1000;
  const validCache = cachedAt && Date.now() < expiringCacheTimestamp;
  if (validCache) {
    return;
  }

  dispatch(checkoutSlice.actions.clearCheckout());
  dispatch(checkoutSlice.actions.setCachedAt({ cachedAt: Date.now() }));
};

export default checkoutSlice.reducer;

export const rAlphabetics = /[a-zA-Z]/g;

export const selectStatefulCosts = (fullState) => {
  const { isInLoco, inLocoRequirePayment, fidelity } = fullState.shop;
  const { validOrders } = fullState.user;
  const checkout = fullState.checkout;
  const subtotal = Decimal(fullState.shoppingCart.cachedTotalCosts);
  const deliveryFee = get(checkout, "searchedDelivery.fee", null);
  const fidelityDiscount =
    fidelity && fidelity.ordersQuantity === validOrders.length
      ? Decimal(fidelity.discountValue)
      : null;

  let couponError = checkout.couponError;
  if (checkout.isValidCoupon && !couponError) {
    if (subtotal.lessThan(checkout.couponMinimalSubtotal)) {
      couponError = `Pedido mínimo do cupom é de ${toPriceDisplay(
        checkout.couponMinimalSubtotal
      )}. Adicione mais produtos.`;
    }
  }

  let deliveryError = checkout.locationError;
  if (checkout.toDelivery && !deliveryError) {
    if (!deliveryFee || (deliveryFee.neighborhood && !deliveryFee.enable)) {
      deliveryError = "Desculpe, não entregamos nesse endereço.";
    }
  }

  const hasValidCoupon = !isInLoco && checkout.isValidCoupon && !couponError;

  const hasFees = !isInLoco && checkout.toDelivery && !deliveryError;

  const hasTakeoutDiscount =
    !isInLoco && checkout.toGo && fullState.shop.takeoutDiscount > 0;

  const couponDiscount = hasValidCoupon
    ? subtotal
        .times(checkout.couponPercentageDiscount)
        .dividedBy(100)
        .plus(checkout.couponAbsoluteDiscount)
        .toDecimalPlaces(2)
    : null;

  const deliveryPrice = hasFees ? Decimal(deliveryFee.price) : null;

  const takeoutDiscount = hasTakeoutDiscount
    ? subtotal
        .times(fullState.shop.takeoutDiscount)
        .dividedBy(100)
        .toDecimalPlaces(2)
    : null;

  const total = subtotal
    .minus(couponDiscount || 0)
    .minus(takeoutDiscount || 0)
    .minus(fidelityDiscount || 0)
    .plus(deliveryPrice || 0);

  let paymentIsCash = null;
  let paymentError = null;
  let paymentValue = null;
  let paymentDiff = null;
  if (isInLoco && !inLocoRequirePayment) {
    paymentIsCash = false;
    paymentValue = null;
  } else if (checkout.payment === CASH_OPTION.name) {
    paymentIsCash = true;
    paymentValue = Decimal(checkout.cash).dividedBy(100);
    paymentDiff = paymentValue.minus(total);
    if (paymentValue.greaterThan(1000)) {
      paymentError = `Valor do pagamento é muito alto. Máximo de ${toPriceDisplay(
        1000
      )}`;
    } else if (paymentDiff.lessThan(0)) {
      paymentError = `Valor do pagamento é insuficiente. Mínimo de ${toPriceDisplay(
        total
      )}`;
    }
  } else {
    paymentIsCash = false;
    paymentValue = total;
  }

  return {
    showSubtotal: hasFees || hasValidCoupon || hasTakeoutDiscount,
    hasFees,
    delivery: deliveryPrice?.toString(),
    deliveryError,

    hasValidCoupon,
    discount: couponDiscount?.toString(),
    couponError,

    hasTakeoutDiscount,
    takeoutDiscount: takeoutDiscount?.toString(),

    subtotal: subtotal.toString(),
    total: total.toString(),

    paymentIsCash,
    paymentValue: paymentValue?.toString(),
    paymentDiff: paymentDiff?.toString(),
    paymentError,
    fidelityDiscount: fidelityDiscount?.toString(),
  };
};

export const selectStateSubmitValidations = (workingTime) => (fullState) => {
  const {
    deliveryMinimalCost: deliveryMinimalCostString,
    isInLoco,
    inLocoText,
    isOrderSchedulingEnabled,
  } = fullState.shop;

  const { toGo, toDelivery, orderScheduling, payment, isSubmitting } =
    fullState.checkout;

  const hasCommitedOrder = fullState.shoppingCart.productOrders.some(
    (o) => o.commited
  );
  const commitedOrders = fullState.shoppingCart.productOrders.filter(
    (o) => o.commited
  );
  const cachedTotalCosts = fullState.shoppingCart.cachedTotalCosts;
  const deliveryMinimalCost = new Decimal(deliveryMinimalCostString);
  const notEnoughToDelivery =
    toDelivery && Decimal(cachedTotalCosts).lessThan(deliveryMinimalCost);

  const hasProductsUnavailableToDelivery =
    toDelivery &&
    commitedOrders.some((product) => !product.product.deliveryVisible);

  const isScheduledOrder =
    isOrderSchedulingEnabled && orderScheduling.isScheduledOrder;

  const isInlocoClosed = isInLoco && !workingTime.isWorkingInloco;
  const isDeliveryClosed = toDelivery && !workingTime.isWorkingDelivery;
  const isToGoClosed = toGo && !workingTime.isWorkingToGo;

  const paymentRequired = isInLoco
    ? !payment && fullState.shop.inLocoRequirePayment
    : !payment;

  let message = null;

  if (isSubmitting) {
    message = "Enviando pedido.";
  }

  if (!hasCommitedOrder) {
    message = "Sacola vazia, adicione mais produtos.";
  }

  if (notEnoughToDelivery) {
    message = `Pedido mínimo para entrega: ${toPriceDisplay(
      deliveryMinimalCost
    )}`;
  }

  if (hasProductsUnavailableToDelivery) {
    message = `Um dos produtos não está disponível para entrega`;
  }

  if (isInlocoClosed) {
    message = "Loja fechada para pedidos na mesa";
  }

  if (isDeliveryClosed && !isScheduledOrder) {
    message = "Loja fechada para delivery";
  }

  if (isToGoClosed && !isScheduledOrder) {
    message = "Loja fechada para retirada";
  }

  let disable;
  if (message) {
    disable = true;
  } else {
    disable = false;
    if (paymentRequired) {
      message = "Escolher a forma de pagamento";
    } else if (isScheduledOrder) {
      message = "Finalizar agendamento";
    } else if (toGo) {
      message = "Finalizar pedido para retirar";
    } else if (toDelivery) {
      message = "Finalizar pedido para entrega";
    } else if (isInLoco) {
      message = inLocoText
        ? `Finalizar pedido (${inLocoText})`
        : `Finalizar pedido`;
    }
  }

  return {
    message,
    disable,
    paymentRequired,
    isScheduledOrder,
  };
};

export const CASH_OPTION = Object.freeze({ id: 1, name: "Dinheiro" });
export const MERCADO_PAGO_OPTION = Object.freeze({
  id: 2,
  name: "Mercado pago",
});
export const PIX_OPTION = Object.freeze({ id: 3, name: "PIX QR Code" });
export const POS_PRESENTIAL_OPTION = Object.freeze({
  id: 3,
  name: "Maquininha de cartão",
});

const findDeliveryFee = (deliveryFees = [], distance = {}) => {
  const sortedFees = sortBy(deliveryFees, "maxDistanceKms");
  if (distance.kms > last(sortedFees).maxDistanceKms) {
    return null;
  }

  const higherFittingFee = sortedFees.find(
    (fee) => distance.kms <= fee.maxDistanceKms
  );
  return higherFittingFee || null;
};

const parseDataForCache = (
  pallasResponseData,
  productOrders,
  costs,
  fullState
) => {
  try {
    const {
      createdAt,
      id: orderId,
      shortReference,
      deliveryDateTime,
      payment,
      paymentName,
    } = pallasResponseData;
    const { items: products, questionsByProduct } = fullState.products;
    const orderedProducts = productOrders.reduce((acc, order) => {
      const found = products.find((p) => p.id === order.productId);
      if (found) {
        const options = questionsByProduct[found.id].reduce(
          (qs, q) => [...qs, ...q.options],
          []
        );
        const answeredOptions = order.answers.reduce(
          (answereds, answer) => [
            ...answereds,
            {
              ...answer,
              ...(options.find((o) => o.id === answer.optionId) || {}),
              ...(questionsByProduct[found.id].find(
                (q) => q.id === answer.questionId
              ) || {}),
            },
          ],
          []
        );
        const detailsList = answeredOptions.map((a) =>
          a.pizza && a.pizzaPriceMode
            ? `${a.qtt}/${a.maxQtt} - ${a.label || "?"}`
            : `${a.qtt}x ${a.label || "?"}`
        );

        if (order.observations) {
          detailsList.push(`Observação: ${order.observations}`);
        }

        const orderedProduct = {
          uuid: order.uuid,
          id: found.id,
          price: order.cachedCost,
          name: `${order.qtt}x ${found.name || "?"}`,
          detailsList,
        };
        acc.push(orderedProduct);
      }

      return acc;
    }, []);

    const paymentQRData =
      paymentName === PIX_OPTION.name && payment && payment.qr_data
        ? payment.qr_data
        : null;

    const paymentPreference =
      paymentName === MERCADO_PAGO_OPTION.name && payment ? payment : null;

    return {
      orderId,
      createdAt,
      shortReference,
      deliveryDateTime,
      orderedProducts,
      shopId: fullState.shop.id,
      shopName: fullState.shop.name,
      isInLoco: fullState.shop.isInLoco,
      tableId: fullState.shop.tableId,
      checkout: fullState.checkout,
      costs,
      paymentQRData,
      paymentPreference,
    };
  } catch (error) {
    console.error("Unexpected error on parseDataForCache", error);
    return null;
  }
};
