const API_PALLAS_URL = "https://api.sistemapallas.com.br";
// const API_PALLAS_URL = 'http://localhost:8080';

export const FIREBASE_BASE_URL = "https://pallas-delivery.firebaseio.com/menus";

export const API_PALLAS_AUTH_URL = `${API_PALLAS_URL}//LocalAuth/authCliente`;
export const API_PALLAS_APPS_URL = `${API_PALLAS_URL}/Apps`;

export const WHATSAPP_CHAT_URL = "https://wa.me";

export const STATE_STEP = Object.freeze({
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  READY: "READY",
  ERROR: "ERROR",
});

export const STATUS_DETAILS = {
  DONE: {
    code: "DONE",
    labelCompleted: "Realizado",
    completed: "Pedido enviado à loja.",
  },
  PAYMENT: {
    code: "INTEGRATED",
    labelWaiting: "Aguardando Pagamento",
    labelCompleted: "Pagamento Aprovado",
    waiting: "O preparo do pedido será iniciado após o pagamento.",
    completed: "Pagamento realizado com sucesso!",
    payment: true,
  },
  CONFIRMED: {
    code: "CONFIRMED",
    labelWaiting: "Aguardando Confirmação",
    labelCompleted: "Confirmado",
    waiting: "Aguardando a confirmação da loja.",
    completed: "A loja confirmou o pedido e iniciou o preparo.",
  },
  READY_TO_DELIVER: {
    code: "READY_TO_DELIVER",
    labelWaiting: "Preparando pedido",
    labelCompleted: "Pedido Pronto",
    waiting: "Aguardando o pedido ficar pronto",
    completed: "O pedido está pronto e aguarda ser retirado.",
  },
  DISPATCHED: {
    code: "DISPATCHED",
    labelWaiting: "Preparando pedido",
    labelCompleted: "Pedido Enviado",
    waiting: "Aguardando o pedido ficar pronto",
    completed: "O pedido saiu para entrega.",
  },
  CANCELLED: {
    code: "CANCELLED",
    labelCompleted: "Pedido Cancelado",
    completed: "A loja teve algum problema e cancelou seu pedido.",
    cancelled: true,
  },
};

export const WEEK_DAYS_PT = [
  "Domingo",
  "Segunda-feira",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];
