import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";
import domScrollTo from "../utils/domScrollTo";
import addLineBreaks from "../utils/addLineBreaks";
import {
  startOrder,
  startEditOrder,
  cancelOrder,
  PRICE_MODE_MAX,
} from "../ducks/shoppingCartSlice";
import LoadingWall from "../components/LoadingWall";
import CommonHeader from "../containers/CommonHeader";
import QuestionsCards from "../containers/QuestionsCards";
import Loading from "../components/Loading";
import FloatingProductOrderFooter from "../containers/FloatingProductOrderFooter";
import { requireProducts } from "../ducks/productsSlice";
import { answerComboQuestion } from "../ducks/shoppingCartSlice";
import { STATE_STEP } from "../ducks/consts";
import useMPSecurity from "../hooks/useMPSecurity";
import productUnitPriceDisplay from "../utils/productPriceDisplay";

export default function ProductScreenWrapper() {
  const routeMatch = useRouteMatch();
  const orderUuidEdit = routeMatch.params.orderUuid;
  const productId = parseInt(routeMatch.params.productId, 10);
  const product = useSelector((state) =>
    state.products.items.find((p) => p.id === productId)
  );

  if (!product) {
    return (
      <>
        <CommonHeader text='Detalhes do produto' />
        <Container className='text-center mt-3 mb-3'>
          <h1>Ops...</h1>
          <p>Este produto não existe mais.</p>
        </Container>
      </>
    );
  }

  return (
    <ProductScreen
      productId={productId}
      product={product}
      orderUuidEdit={orderUuidEdit}
    />
  );
}

function ProductScreen({ productId, product, orderUuidEdit }) {
  const dispatch = useDispatch();
  const stateStep = useSelector(
    (state) => state.products.questionsStateByProduct[productId]
  );
  const {
    isAvailableToOrder,
    messageToOrder,
    pizzaPriceMode,
    mercadoPagoKey,
    kilogramStep,
  } = useSelector((state) => state.shop);
  useMPSecurity(mercadoPagoKey, "item");

  const isOrderVisible = isAvailableToOrder && stateStep === STATE_STEP.READY;

  const [orderUuid, setOrderUuid] = React.useState(null);
  const alredyAnswerCombo = useSelector(
    (state) => state.shoppingCart.answersByOrderCombo[orderUuid]
  );

  React.useEffect(() => {
    domScrollTo(0, "auto");
  }, []);

  React.useEffect(() => {
    dispatch(requireProducts(productId));
  }, [productId, dispatch]);

  React.useEffect(() => {
    const uuid = uuidv4();
    if (orderUuidEdit) {
      dispatch(startEditOrder({ uuid, productId, uuidEdit: orderUuidEdit }));
    } else {
      dispatch(startOrder({ uuid, productId, kilogramStep }));
    }
    setOrderUuid(uuid);
  }, [orderUuidEdit, productId, kilogramStep, dispatch]);

  React.useEffect(() => {
    if (orderUuid && stateStep === STATE_STEP.READY && !alredyAnswerCombo) {
      dispatch(answerComboQuestion({ uuid: orderUuid, productId }));
    }
  }, [stateStep, orderUuid, productId, alredyAnswerCombo, dispatch]);

  const onBack = () => {
    dispatch(cancelOrder({ uuid: orderUuid }));
  };

  if (!orderUuid) {
    return <LoadingWall />;
  }

  return (
    <>
      <div
        className={`product-screen--${isOrderVisible ? "stretched" : "normal"}`}
      >
        <CommonHeader text={product.name} onBack={onBack} />
        <ProductCard product={product} pizzaPriceMode={pizzaPriceMode} />
        <MessageToOrder
          isAvailableToOrder={isAvailableToOrder}
          messageToOrder={messageToOrder}
        />
        {(stateStep === STATE_STEP.LOADING || !alredyAnswerCombo) && (
          <Loading message='Carregando opções...' />
        )}
        {stateStep === STATE_STEP.READY && alredyAnswerCombo === true && (
          <QuestionsCards
            isAvailableToOrder={isAvailableToOrder}
            productId={productId}
            orderUuid={orderUuid}
          />
        )}
        {stateStep === STATE_STEP.ERROR && (
          <Container className='text-center mt-3 mb-3'>
            <p>Erro inesperado, tente novamente mais tarde.</p>
          </Container>
        )}
        <MessageToOrder
          isAvailableToOrder={isAvailableToOrder}
          messageToOrder={messageToOrder}
        />
      </div>
      {isOrderVisible && (
        <FloatingProductOrderFooter
          orderUuid={orderUuid}
          orderUuidEdit={orderUuidEdit}
        />
      )}
    </>
  );
}

const ProductCard = ({ product, pizzaPriceMode }) => (
  <Container>
    <Card>
      {product.pictureUrl && (
        <Card.Img
          src={product.pictureUrl}
          alt='Ilustração do produto'
          height={200}
          variant='top'
          className='image-fit-cover'
        />
      )}
      <Card.Body>
        {product.disabled && (
          <Badge className='mb-3' variant='secondary'>
            <small>INDISPONÍVEL</small>
          </Badge>
        )}
        <Card.Text className='text-muted'>
          {addLineBreaks(product.details)}
        </Card.Text>
        <Card.Text>{productUnitPriceDisplay(product)}</Card.Text>
        {product.pizza && pizzaPriceMode === PRICE_MODE_MAX && (
          <Card.Text className='text-muted small'>
            A pizza com vários sabores é cobrada pelo preço do sabor mais caro.
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  </Container>
);

const MessageToOrder = ({ isAvailableToOrder, messageToOrder }) => {
  return isAvailableToOrder ? null : (
    <Container className='mt-4'>
      <Alert variant='info' className='text-center'>
        <span className='d-block'>{messageToOrder}</span>
      </Alert>
    </Container>
  );
};
