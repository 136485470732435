import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { flushPendingOrders } from "../ducks/shoppingCartSlice";
import ShopHeader from "../containers/ShopHeader";
import GroupedProductsView from "../containers/GroupedProductsView";
import GroupedProductsViewInstagram from "../containers/GroupedProductsViewInstagram";
import GroupsListView from "../containers/GroupsListView";
import ShopFooter from "../containers/ShopFooter";
import ShopInfo from "../containers/ShopInfo";
import FloatingCartSummary from "../containers/FloatingCartSummary";
import BasicSearch from "../containers/BasicSearch";
import TableConfirmationModal from "../containers/TableConfirmationModal";
import StatusButton from "../components/StatusButton";
import ProductsHighlights from "../containers/ProductsHighlights";
import useMPSecurity from "../hooks/useMPSecurity";

export default function ShopHomeScreen() {
  const dispatch = useDispatch();
  const {
    tableId,
    isAvailableToOrder,
    layoutGroupsFirst,
    mercadoPagoKey,
    isSelfchekout,
    isSelfchekoutPOS,
  } = useSelector((state) => state.shop);
  useMPSecurity(mercadoPagoKey, "home");

  const orders = useSelector((state) => state.shoppingCart.productOrders);
  const commitedOrders = orders.filter((o) => o.commited);
  const isCartVisible = isAvailableToOrder && commitedOrders.length > 0;

  React.useEffect(() => {
    const safetyDelay = setTimeout(() => {
      dispatch(flushPendingOrders());
    }, 3000);
    return () => clearTimeout(safetyDelay);
  }, [dispatch]);

  if (isSelfchekoutPOS) {
    return (
      <>
        <div
          className={`shop-home-screen--${
            isCartVisible ? "stretched" : "normal"
          }`}
        >
          <GroupedProductsViewInstagram />
        </div>
        {isCartVisible && <FloatingCartSummary />}
      </>
    );
  }

  return (
    <>
      <div
        className={`shop-home-screen--${
          isCartVisible ? "stretched" : "normal"
        }`}
      >
        <ShopHeader />
        <BasicSearch />
        <ProductsHighlights />
        {layoutGroupsFirst ? <GroupsListView /> : <GroupedProductsView />}
        <ShopInfo />
        <ShopFooter />
      </div>
      <StatusButton isCartVisible={isCartVisible} />
      {isCartVisible && <FloatingCartSummary />}
      {isAvailableToOrder && tableId && !isSelfchekout ? (
        <TableConfirmationModal tableId={tableId} />
      ) : null}
    </>
  );
}
