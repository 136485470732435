import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function LoadingWall() {
  return (
    <div className="d-flex flex-column w-100 vh-100 align-items-center justify-content-center">
      <Spinner animation="grow" variant="danger" />
      <h1 className="mt-4">Carregando...</h1>
    </div>
  );
}
