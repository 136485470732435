import { useEffect } from 'react';

const useMPSecurity  = (mercadoPagoKey, view) => {
  useEffect(() => {
    if (!mercadoPagoKey) {
      return;
    }
    
    const script = document.createElement('script');
    script.src = 'https://www.mercadopago.com/v2/security.js';
    script.view = view;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [mercadoPagoKey, view]);
};

export default useMPSecurity;