import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Card from 'react-bootstrap/Card';
import { setObservations } from '../ducks/shoppingCartSlice';

const ObservationsCard = ({ isAvailableToOrder, orderUuid }) => {
    const dispatch = useDispatch();
    const productOrder = useSelector(state => state.shoppingCart.productOrders.find(o => o.uuid === orderUuid));
    if (!productOrder || !isAvailableToOrder) {
      return null;
    }
  
    const observations = productOrder.observations;
  
    const onChangeObservations = event => dispatch(setObservations({
      uuid: orderUuid,
      observations: event.target.value,
    }));
  
    return (
      <Card className="mt-4">
        <Card.Title
          as="h3"
          className="d-flex flex-row justify-content-between align-items-end bg-light m-0 p-3"
        >
          <span>
            Alguma observação?
          </span>
        </Card.Title>      
        <TextField
          fullWidth={true}
          variant="outlined"
          placeholder="Ex: sem cebola, sem molho, etc..."
          label=""
          onChange={onChangeObservations}
          value={observations}
        />
      </Card>
    );
  };

  export default ObservationsCard;