import React from "react";
import { useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import useGoTo from "../hooks/useGoTo";

export default function CommonHeader({
  text = "",
  onBack = () => {},
  showBack = true,
  className,
}) {
  const history = useHistory();
  const goTo = useGoTo();
  const onBackClick = () => {
    const signalToProceed = onBack();
    if (signalToProceed !== undefined && signalToProceed === false) {
      return;
    }

    if (history.action === "PUSH") {
      history.goBack();
    } else {
      goTo("/");
    }
  };

  return (
    <Navbar
      variant="light"
      bg="light"
      sticky="top"
      className={`p-0 m-0 ${className}`}
    >
      {showBack && (
        <Button
          className=""
          variant="link"
          onClick={onBackClick}
          style={{ position: "absolute" }}
        >
          <KeyboardBackspaceIcon />
        </Button>
      )}
      <Navbar.Brand className="w-100 py-3 m-0 text-navbar">{text}</Navbar.Brand>
    </Navbar>
  );
}
