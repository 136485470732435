import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import toPriceDisplay from "../utils/toPriceDisplay";
import useGoTo from "../hooks/useGoTo";

export default function FloatingCartSummary() {
  const goTo = useGoTo();
  const {
    commitedOrders,
    cachedTotalCosts,
    isSelfchekout,
    isInLoco,
    inLocoText,
  } = useSelector((state) => ({
    isSelfchekout: state.shop.isSelfchekout,
    isInLoco: state.shop.isInLoco,
    inLocoText: state.shop.inLocoText,
    commitedOrders: state.shoppingCart.productOrders.filter((o) => o.commited),
    cachedTotalCosts: state.shoppingCart.cachedTotalCosts,
  }));

  const label =
    !isSelfchekout && isInLoco && inLocoText
      ? `Ver sacola (${inLocoText})`
      : "Ver sacola";

  return (
    <Container className="m-0 p-0 fixed-bottom bg-white">
      <Button
        variant="danger"
        className="w-100 d-flex justify-content-between p-3 rounded-0"
        onClick={() => goTo("/checkout")}
      >
        <span>
          <ShoppingCartIcon /> <small>{commitedOrders.length}</small>
        </span>
        <span>{label}</span>
        <span>{toPriceDisplay(cachedTotalCosts)}</span>
      </Button>
    </Container>
  );
}
