import React from "react";

export default function SchedulerDay({
  date,
  borderColor,
  textColor,
  classNames,
  ...rest
}) {
  return (
    <button
      key={date.getTime()}
      className={`d-flex flex-column align-items-center bg-white rounded text-capitalize border ${borderColor} ${classNames}`}
      {...rest}
    >
      <small className={`${textColor} font-weight-bold pt-1`}>
        {new Date().getDate() === date.getDate()
          ? "Hoje"
          : date
              .toLocaleDateString("pt-BR", {
                weekday: "short",
              })
              .replace(`.`, ``)}
      </small>
      <span
        style={{ fontSize: `18px` }}
        className={`${textColor} font-weight-bold pb-1 px-3`}
      >
        {date.toLocaleString("pt-BR", { day: "numeric" })}
      </span>
    </button>
  );
}
