import { createSlice } from '@reduxjs/toolkit';
import { sliceFirebaseShop, sliceFirebaseGroups, sliceFirebaseProducts } from '../utils/firebase-adapters';
import { minimalDelayedHOC } from '../utils/minimalDelayedHOC';
import { setShopDetails, setOrderAvailability } from './shopSlice';
import { loadMarketplace } from './marketplaceSlice';
import { setGroupedProductsWithQuestions } from './productsSlice';
import { requireProducts } from '../ducks/productsSlice';
import { refreshCachedCosts } from '../ducks/shoppingCartSlice';
import { validateCache } from '../ducks/checkoutSlice';
import { STATE_STEP, FIREBASE_BASE_URL } from './consts';

export const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    namespace: '',
    showOnlyStatus: false,
    step: STATE_STEP.INITIAL,
    error: null,
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.step = STATE_STEP.LOADING;
      state.namespace = payload.namespace;
      state.showOnlyStatus = payload.showOnlyStatus;
    },
    setReady: (state) => {
      state.step = STATE_STEP.READY;
    },
    setError: (state, action) => {
      state.step = STATE_STEP.ERROR;
      state.error = action.payload;
    },
  },
});

const { setLoading, setReady, setError } = appStateSlice.actions;

const loadShop = async (namespace, dispatch) => {
  const script = document.getElementById('jsonEmbedded');
  const loja = script
    ? JSON.parse(script.innerHTML)
    : await fetch(`${FIREBASE_BASE_URL}/${namespace}/loja.json`).then(response => response.json());
  const shop = sliceFirebaseShop(loja);

  dispatch(setShopDetails(shop));
};

const loadProducts = async (namespace, orders, isInLoco, dispatch) => {
  const orderType = isInLoco ? "mesa" : "entrega";
  const response = await fetch(`${FIREBASE_BASE_URL}/${namespace}/${orderType}.json`);
  const data = await response.json();
  const parsed = {
    groups: sliceFirebaseGroups(data.grupos),
    items: sliceFirebaseProducts(data.produtos),
  };
  await dispatch(setGroupedProductsWithQuestions(parsed));
  await dispatch(setOrderAvailability({ isAvailableToOrder: data['podePedir'] }));
  await Promise.all(
    orders.map(order => dispatch(requireProducts(order.productId)))
  );
  await dispatch(refreshCachedCosts());
  await dispatch(validateCache());
}

export const fetchAppEssentials = (namespace, isInLoco, showOnlyStatus) => async (dispatch, getState) => {
  const isMarketplace = window.location.pathname.includes('marketplace');

  const calmDispatch = minimalDelayedHOC(dispatch, 1200);

  dispatch(setLoading({
    namespace,
    showOnlyStatus
  }));

  const orders = getState().shoppingCart.productOrders.filter(o => o.commited);

  try {
    await loadShop(namespace, dispatch);

    if (isMarketplace) {
      await loadMarketplace(namespace, dispatch);
    } else {
      if (!showOnlyStatus) {
        await loadProducts(namespace, orders, isInLoco, dispatch);
      }
    }

    calmDispatch(setReady());
  }
  catch (error) {
    calmDispatch(setError(error));
  }
};

export default appStateSlice.reducer;
