import React from "react";
import Alert from "react-bootstrap/Alert";
import { useSelector } from "react-redux";
import get from "lodash.get";
import domScrollTo from "../utils/domScrollTo";
import CommonHeader from "../containers/CommonHeader";
import CartView from "../containers/CartView";
import CouponSelection from "../containers/CouponSelection";
import DeliverySelection from "../containers/DeliverySelection";
import PaymentSelection from "../containers/PaymentSelection";
import PaymentSelectionInLoco from "../containers/PaymentSelectionInLoco";
import CheckoutForm from "../containers/CheckoutForm";
import InLocoForm from "../containers/InLocoForm";
import FidelityBox from "../components/FidelityBox";
import Divider from "../components/Divider";
import OrderScheduler from "../containers/OrderScheduler";
import SelfCheckoutPOS from "../containers/Selfcheckout/SelfCheckoutPOS";
import SelfCheckoutQRCode from "../containers/Selfcheckout/SelfCheckoutQRCode";
import SelfCheckoutTotem from "../containers/Selfcheckout/SelfCheckoutTotem";

function CheckoutSteps() {
  const { toGo, toDelivery } = useSelector((state) => state.checkout);
  const {
    isInLoco,
    inLocoRequirePayment,
    isSelfchekoutPOS,
    isSelfchekoutQRCode,
    isSelfchekoutTotem,
    isOrderSchedulingEnabled,
  } = useSelector((state) => state.shop);
  const showAll = toGo || toDelivery;

  if (isSelfchekoutPOS) {
    return <SelfCheckoutPOS />;
  }

  if (isSelfchekoutQRCode) {
    return <SelfCheckoutQRCode />;
  }

  if (isSelfchekoutTotem) {
    return <SelfCheckoutTotem />;
  }

  if (isInLoco) {
    return (
      <>
        <InLocoForm />
        <Divider />
        {inLocoRequirePayment && (
          <>
            <PaymentSelectionInLoco />
            <Divider />
          </>
        )}
        <CheckoutForm />
      </>
    );
  }

  return (
    <>
      <FidelityBox className="p-3" divider={<Divider />} />
      <DeliverySelection />
      {showAll && (
        <>
          {isOrderSchedulingEnabled && (
            <>
              <Divider />
              <OrderScheduler />
            </>
          )}
          <Divider />
          <CouponSelection />
          <Divider />
          <PaymentSelection />
          <Divider />
          <CheckoutForm />
        </>
      )}
    </>
  );
}

export default function CheckoutScreen() {
  React.useEffect(() => {
    domScrollTo(0, "auto");
  }, []);

  return (
    <>
      <CommonHeader text="Sacola" />
      <Divider />
      <CheckoutErrorAlert />
      <CartView />
      <Divider />
      <CheckoutSteps />
    </>
  );
}

function CheckoutErrorAlert() {
  const submitError = useSelector((state) => state.checkout.submitError);
  if (!get(submitError, "details.status")) {
    return null;
  }

  return (
    <Alert className="mt-2 mb-2 ml-2 mr-2" variant={"danger"}>
      {submitError.message}
    </Alert>
  );
}
