import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { useDispatch, useSelector } from "react-redux";
import {
  setInLocoAnswer,
  setFormAnswer,
  setIdentification,
  clearCheckoutTotem,
} from "../ducks/checkoutSlice";
import { PhoneInput, CPFCNPJInput } from "../components/NumberInput";
import CheckoutServerError from "./CheckoutServerError";
import { Form, Spinner } from "react-bootstrap";

const STEP_TakingHome = "STEP_TakingHome";
const STEP_PersonalData = "STEP_PersonalData";
const STEP_CPFQuestion = "STEP_CPFQuestion";
const STEP_CPFNumber = "STEP_CPFNumber";
const STEP_Table = "STEP_Table";

const styles = {
  title: {
    fontSize: 30,
    textAlign: "center",
    marginTop: 60,
    fontWeight: 600,
  },
  body: {
    display: `flex`,
    paddingRight: 10,
    paddingLeft: 10,
    marginTop: 90,
    paddingBottom: 90,
  },
  form: {
    width: `100%`,
  },
  button: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 20,
  },
  buttonBack: {
    marginLeft: 10,
    marginBottom: 30,
    paddingLeft: 20,
    paddingRight: 20,
  },
  largeIcon: {
    marginTop: 10,
    width: 60,
    height: 60,
  },
  spinner: {
    width: 60,
    height: 60,
    margin: "auto",
  },
};

function TakingHome({ step, moveStep }) {
  const dispatch = useDispatch();

  if (step !== STEP_TakingHome) return null;

  const setTakingHome = (value) => {
    dispatch(
      setInLocoAnswer({
        name: "isTakingHome",
        value,
      })
    );
    moveStep(1);
  };

  return (
    <div>
      <p style={styles.title}>Embalar para viagem?</p>
      <div className="d-flex justify-content-between" style={styles.body}>
        <Button
          className="w-100"
          style={styles.button}
          variant={"secondary"}
          onClick={() => setTakingHome(true)}
        >
          Embalar para viagem
          <br />
          <LocalMallIcon style={styles.largeIcon} />
        </Button>
        <div className="p-2" />
        <Button
          className="w-100"
          style={styles.button}
          variant={"danger"}
          onClick={() => setTakingHome(false)}
        >
          Vou comer aqui
          <br />
          <FastfoodIcon style={styles.largeIcon} />
        </Button>
      </div>
      <Button
        style={styles.buttonBack}
        variant={"danger"}
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

function PersonalData({ step, moveStep }) {
  const dispatch = useDispatch();
  const { name, telephone } = useSelector((state) => state.checkout);

  if (step !== STEP_PersonalData) return null;

  const nameError =
    name.length > 0 && name.length < 4
      ? "Nome com no mínimo 4 caracteres"
      : null;

  const digit3 = telephone.length > 2 ? telephone.substring(2, 3) : null;
  const phoneError =
    telephone.length === 0 || (digit3 === "9" && telephone.length === 11)
      ? null
      : "Celular inválido";

  const disabled = name.length < 4 || telephone.length !== 11;

  const onNameChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "name",
        value: event.target.value,
      })
    );

  const onTelephoneChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "telephone",
        value: event.target.value,
      })
    );

  const onSubmit = (event) => {
    event.preventDefault();
    moveStep(1);
  };

  return (
    <div>
      <p style={styles.title}>Seu dados</p>
      <div style={styles.body}>
        <Form onSubmit={onSubmit} style={styles.form}>
          <TextField
            autoFocus
            fullWidth={true}
            variant="outlined"
            type="text"
            label="Nome"
            error={nameError ? true : false}
            helperText={nameError}
            value={name}
            onChange={onNameChange}
            maxLength={64}
            required
          />
          <TextField
            fullWidth={true}
            className="mt-4"
            variant="outlined"
            label="Celular com DDD"
            error={phoneError ? true : false}
            helperText={phoneError}
            value={telephone}
            onChange={onTelephoneChange}
            InputProps={{
              inputComponent: PhoneInput,
            }}
            required
          />
          <Button
            type="submit"
            className="w-100 p-2 mt-4"
            variant={"danger"}
            disabled={disabled}
          >
            Confirmar
          </Button>
        </Form>
      </div>
      <Button
        style={styles.buttonBack}
        variant={"danger"}
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

function CPFQuestion({ step, moveStep }) {
  const dispatch = useDispatch();

  if (step !== STEP_CPFQuestion) return null;

  const onIdentificationEnableChange = (value) => {
    dispatch(
      setFormAnswer({
        name: "isIdentificationEnable",
        value,
      })
    );
    moveStep(value ? 1 : 2);
  };

  return (
    <div>
      <p style={styles.title}>CPF na nota?</p>
      <div className="d-flex justify-content-between" style={styles.body}>
        <Button
          className="w-100"
          style={styles.button}
          variant={"secondary"}
          onClick={() => onIdentificationEnableChange(false)}
        >
          Não
        </Button>
        <div className="p-2" />
        <Button
          className="w-100"
          style={styles.button}
          variant={"danger"}
          onClick={() => onIdentificationEnableChange(true)}
        >
          Sim
        </Button>
      </div>

      <Button
        style={styles.buttonBack}
        variant={"danger"}
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

function CPFNumber({ step, moveStep }) {
  const dispatch = useDispatch();
  const { identification, identificationError } = useSelector(
    (state) => state.checkout
  );

  if (step !== STEP_CPFNumber) return null;

  const onIdentificationChange = (event) =>
    dispatch(setIdentification(event.target.value));

  const onSubmit = (event) => {
    event.preventDefault();
    moveStep(1);
  };

  const disabled = !identification || !!identificationError;

  return (
    <div>
      <p style={styles.title}>CPF ou CNPJ</p>
      <div style={styles.body}>
        <Form onSubmit={onSubmit} style={styles.form}>
          <TextField
            autoFocus
            fullWidth={true}
            error={identificationError ? true : false}
            helperText={identificationError}
            variant="outlined"
            label=""
            value={identification}
            onChange={onIdentificationChange}
            required
            InputProps={{
              inputComponent: CPFCNPJInput,
            }}
          />
          <Button
            type="submit"
            className="w-100 p-2 mt-4"
            variant={"danger"}
            disabled={disabled}
          >
            Confirmar
          </Button>
        </Form>
      </div>
      <Button
        style={styles.buttonBack}
        variant={"danger"}
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

function Table({ step, moveStep }) {
  const dispatch = useDispatch();
  const { inLocoLocation } = useSelector((state) => state.checkout);

  if (step !== STEP_Table) return null;

  const onInLocoLocationChange = (event) =>
    dispatch(
      setFormAnswer({
        name: "inLocoLocation",
        value: event.target.value,
      })
    );

  const onSubmit = (event) => {
    event.preventDefault();
    moveStep(1);
  };

  const disabled = !inLocoLocation;

  return (
    <div>
      <p style={styles.title}>Entregar os produtos em qual mesa?</p>
      <div style={styles.body}>
        <Form onSubmit={onSubmit} style={styles.form}>
          <TextField
            fullWidth={true}
            style={styles.input}
            helperText="Ex: Mesa 12 ou Balcão"
            variant="outlined"
            label=""
            value={inLocoLocation}
            onChange={onInLocoLocationChange}
            required
            inputProps={{ maxLength: 30 }}
          />
          <Button
            type="submit"
            className="w-100 p-2 mt-4"
            variant={"danger"}
            disabled={disabled}
          >
            Confirmar
          </Button>
        </Form>
      </div>
      <Button
        style={styles.buttonBack}
        variant={"danger"}
        onClick={() => moveStep(false)}
      >
        Voltar
      </Button>
    </div>
  );
}

export default function QuestionCheckout({
  table,
  takingHome,
  personalData,
  cpfQuestion,
  dispatchSubmitCheckout,
}) {
  const dispatch = useDispatch();
  const { isSubmitting } = useSelector((state) => state.checkout);
  const [step, setStep] = React.useState("");
  const allSteps = [];

  if (table) {
    allSteps.push(STEP_Table);
  }

  if (takingHome) {
    allSteps.push(STEP_TakingHome);
  }

  if (personalData) {
    allSteps.push(STEP_PersonalData);
  }

  if (cpfQuestion) {
    allSteps.push(STEP_CPFQuestion);
    allSteps.push(STEP_CPFNumber);
  }

  const startSteps = () => {
    if (allSteps.length === 0) {
      dispatchSubmitCheckout();
      return;
    }

    setStep(allSteps[0]);
  };

  const moveStep = (next) => {
    const currentIndex = allSteps.indexOf(step);
    if (next === false) {
      if (currentIndex === 0) {
        dispatch(clearCheckoutTotem());
        setStep("");
        return;
      }

      setStep(allSteps[currentIndex - 1]);
      return;
    }

    if (next === 1 || next === 2) {
      const nextIndex = currentIndex + next;
      if (nextIndex === allSteps.length) {
        dispatchSubmitCheckout();
        return;
      }

      setStep(allSteps[nextIndex]);
      return;
    }
  };

  if (isSubmitting) {
    return (
      <Modal
        show={true}
        backdrop="static"
        fullscreen={true}
        dialogClassName="m-0"
      >
        <div>
          <p style={styles.title}>Enviando Pedido</p>
          <div style={styles.body}>
            <Spinner animation="border" style={styles.spinner} />
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div className="p-3">
      <Button className="w-100 p-2" variant={"danger"} onClick={startSteps}>
        Finalizar Pedido
      </Button>
      <Modal
        show={!!step}
        backdrop="static"
        fullscreen={true}
        dialogClassName="mx-auto mt-5"
      >
        <Modal.Body>
          <Table step={step} moveStep={moveStep} />
          <TakingHome step={step} moveStep={moveStep} />
          <PersonalData step={step} moveStep={moveStep} />
          <CPFQuestion step={step} moveStep={moveStep} />
          <CPFNumber step={step} moveStep={moveStep} />
        </Modal.Body>
      </Modal>
      <CheckoutServerError />
    </div>
  );
}

export { TakingHome, PersonalData, CPFQuestion, CPFNumber };
