import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Figure from "react-bootstrap/Figure";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import toPriceDisplay from "../utils/toPriceDisplay";
import useWorkingTime from "../hooks/useWorkingTime";
import Avatar from "../components/Avatar";
import FidelityBox from "../components/FidelityBox";

export default function ShopHeader() {
  const shop = useSelector((state) => state.shop);
  const { isWorking } = useWorkingTime(shop);
  const distancesFree = shop.deliveryFees
    .filter((f) => f.price === "0")
    .map((f) => f.maxDistanceKms);
  const maxDistanceFree =
    distancesFree.length === 0 ? 0 : Math.max(...distancesFree);
  const showAlert =
    (shop.deliveryEnable && maxDistanceFree > 0) ||
    (shop.deliveryEnable && shop.deliveryMinimalCost > 0) > 0 ||
    (shop.takeoutEnable && shop.takeoutDiscount > 0);

  const inLocoReadyToOrder =
    shop.isAvailableToOrder &&
    ((shop.inLocoRequireToken && shop.token) || !shop.inLocoRequireToken);
  const inLocoTokenRequire =
    shop.isAvailableToOrder &&
    shop.inLocoRequireToken &&
    !shop.token &&
    !shop.isSelfchekout;

  return (
    <Container as="header" className="shop-header mb-3">
      <Row className="justify-content-center">
        {shop.pictureUrl && (
          <Figure.Image
            src={shop.pictureUrl}
            alt="Logo da loja"
            className="my-4 fixed-logo-figure"
            width="150"
            height="150"
            roundedCircle
          />
        )}
        {!shop.pictureUrl && (
          <div className="avatar_container_shop_header my-4">
            <Avatar name={shop.name} />
          </div>
        )}
      </Row>
      <Row>
        <h1 className="shop-header__title ml-auto mr-auto">{shop.name}</h1>
        {shop.informationHeader && (
          <div
            className="w-100 text-center"
            dangerouslySetInnerHTML={{ __html: shop.informationHeader }}
          />
        )}
      </Row>
      {shop.isAvailableToOrder &&
        shop.isInLoco &&
        shop.inLocoText &&
        !shop.isSelfchekout && (
          <Alert variant="info" className="text-center">
            <span className="d-block">
              {shop.inLocoText}
              <br />
              {inLocoReadyToOrder && "Pronto para pedir!"}
              {inLocoTokenRequire && "Necessita da liberação do atendente!"}
            </span>
          </Alert>
        )}
      {!shop.isInLoco && isWorking && showAlert && (
        <Alert variant="info" className="text-center">
          {shop.deliveryEnable && maxDistanceFree > 0 && (
            <span className="d-block">
              <LocalOfferIcon className="mr-2" />
              Entrega grátis até {maxDistanceFree}km
            </span>
          )}
          {shop.deliveryEnable && shop.deliveryMinimalCost > 0 && (
            <span className="d-block">
              Pedido mínimo para entrega:{" "}
              {toPriceDisplay(shop.deliveryMinimalCost)}
            </span>
          )}
          {shop.takeoutEnable && shop.takeoutDiscount > 0 && (
            <span className="d-block">
              Desconto para retirar: {shop.takeoutDiscount}%
            </span>
          )}
        </Alert>
      )}
      {!isWorking && (
        <Alert variant="secondary" className="text-center">
          Loja <strong>fechada</strong> no momento!
          {shop.isOrderSchedulingEnabled ? (
            <>
              {" "}
              Apenas <strong>agendamento</strong>.
            </>
          ) : (
            ""
          )}
        </Alert>
      )}
      <FidelityBox className="pb-3" />
    </Container>
  );
}
