import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Decimal from "decimal.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import HomeIcon from "@material-ui/icons/Home";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CheckIcon from "@material-ui/icons/Check";
import { CEPInput, AddressNumberInput } from "../components/NumberInput";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { minimalDelayedHOC } from "../utils/minimalDelayedHOC";
import toPriceDisplay from "../utils/toPriceDisplay";
import useGoTo from "../hooks/useGoTo";
import {
  setToGo,
  setToDelivery,
  setDeliveryAddress,
  selectStatefulCosts,
  searchDeliveryCalc,
} from "../ducks/checkoutSlice";
import useWorkingTime from "../hooks/useWorkingTime";
import WorkingTime from "./WorkingTime";
import OrderSchedulerModal from "../components/OrderSchedulerModal";

const styles = {
  input: {
    marginBottom: 15,
  },
  selectError: {
    marginRight: 14,
    marginLeft: 14,
    color: "#f44336",
  },
};

const TakeoutButton = ({ toGo, takeoutDiscount, ...rest }) => {
  return (
    <Button
      className="w-100 p-2"
      variant={toGo ? "danger" : "outline-danger"}
      {...rest}
    >
      {toGo && <CheckIcon className="mr-2" />}
      Retirar no balcão
      {takeoutDiscount > 0 && ` -${takeoutDiscount.toString()}%`}
    </Button>
  );
};

const DeliveryButton = ({ toDelivery, deliveryMinimalCost, text, ...rest }) => {
  return (
    <Button
      className="w-100 p-2"
      variant={toDelivery ? "danger" : "outline-danger"}
      {...rest}
    >
      {toDelivery && <CheckIcon className="mr-2" />}
      {text}
    </Button>
  );
};

export default function DeliverySelection() {
  const dispatch = useDispatch();
  const goTo = useGoTo();
  const shop = useSelector((state) => state.shop);
  const {
    isWorkingDelivery,
    isWorkingToGo,
    rulesDelivery,
    rulesToGo,
    currentWeekDay,
  } = useWorkingTime(shop);
  const { takeoutEnable, deliveryEnable } = shop;
  const { toGo, toDelivery, isSearchingLocation, deliveryAddress } =
    useSelector((state) => state.checkout);
  const { deliveryMinimalCost, takeoutDiscount } = useSelector((state) => ({
    deliveryMinimalCost: Decimal(state.shop.deliveryMinimalCost),
    takeoutDiscount: Decimal(state.shop.takeoutDiscount),
  }));
  const manualAddressEnable = useSelector(
    (state) => state.shop.manualAddressEnable
  );
  const deliveryFees = useSelector((state) => state.shop.deliveryFees);
  const cachedTotalCosts = useSelector(
    (state) => state.shoppingCart.cachedTotalCosts
  );
  const { deliveryError } = useSelector(selectStatefulCosts);
  const [contactForm, setContactForm] = React.useState({});
  const [house, setHouse] = React.useState(false);
  const [apartment, setApartment] = React.useState(false);
  const [postalCode, setPostalCode] = React.useState("");
  const [addressNumber, setAddressNumber] = React.useState("");
  const [reference, setReference] = React.useState("");
  const [apartmentNumber, setApartmentNumber] = React.useState("");
  const [apartmentBlock, setApartmentBlock] = React.useState("");

  const [isDeliveryNotEnoughVisible, setDeliveryNotEnoughVisible] =
    React.useState(false);
  const [isDeliveryNotWorkingVisible, setDeliveryNotWorkingVisible] =
    React.useState(false);
  const [isToGoNotWorkingVisible, setToGoNotWorkingVisible] =
    React.useState(false);
  const [isDeliveryFormVisible, setDeliveryFormVisible] = React.useState(false);
  const [isSchedulerVisible, setSchedulerVisible] = React.useState(false);
  const [isSearchingPostalCode, setSearchingPostalCode] = React.useState(false);
  const [postalCodeError, setPostalCodeError] = React.useState(null);

  const notEnoughToDelivery = new Decimal(cachedTotalCosts).lessThan(
    deliveryMinimalCost
  );

  const deliveryNeighborhoods = deliveryFees
    .reduce((neighborhoods, fee) => {
      if (fee.neighborhood && fee.enable) {
        neighborhoods = [...neighborhoods, fee.neighborhood];
      }

      return neighborhoods;
    }, [])
    .sort();

  const searchLocation = (postalCode) => {
    const postalCodeToSearch = postalCode.trim();
    if (postalCodeToSearch.length < 8 || isSearchingLocation) {
      if (contactForm.postalCode) {
        setPostalCodeError("Preencha o CEP com 8 dígitos.");
      }

      return;
    }

    setSearchingPostalCode(true);

    const calmExecute = minimalDelayedHOC((f) => f(), 1200);

    fetch(`https://viacep.com.br/ws/${postalCodeToSearch}/json/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.cep) {
          const parsed = {
            postalCode: data["cep"],
            addressStreet: data["logradouro"],
            neighborhood: data["bairro"],
            city: data["localidade"],
            state: data["uf"],
          };

          calmExecute(() => {
            setPostalCodeError(null);
            setContactForm(parsed);
            setSearchingPostalCode(false);
          });
        } else {
          calmExecute(() => {
            setPostalCodeError(`CEP ${postalCodeToSearch} não encontrado.`);
            setContactForm({});
            setSearchingPostalCode(false);
          });
        }
      })
      .catch((error) => {
        console.error(
          "Error on fetching address by ZIP.",
          postalCodeToSearch,
          error
        );
        calmExecute(() => setSearchingPostalCode(false));
      });
  };

  const onNumberChange = (event) => setAddressNumber(event.target.value);
  const onApartmentNumberChange = (event) =>
    setApartmentNumber(event.target.value);
  const onApartmentBlockChange = (event) =>
    setApartmentBlock(event.target.value);
  const onReferenceChange = (event) => setReference(event.target.value);
  const onContactFormChange = (fieldName) => (event) =>
    setContactForm({
      ...contactForm,
      [fieldName]: event.target.value,
    });
  const onPostalCodeChange = (event) => {
    setPostalCode(event.target.value);
    if (!manualAddressEnable) {
      searchLocation(event.target.value);
    } else {
      setContactForm({ ...contactForm, postalCode: event.target.value });
    }
  };
  const onSchedulerClose = () => setSchedulerVisible(false);

  const submitDisabled = manualAddressEnable
    ? false
    : isSearchingPostalCode || !contactForm.postalCode || postalCodeError;

  const deliveryAddressLabel = manualAddressEnable
    ? deliveryAddress.neighborhood
    : `${deliveryAddress.neighborhood}, ${deliveryAddress.city}/${deliveryAddress.state} - CEP ${deliveryAddress.postalCode}`;

  const onSubmit = (event) => {
    event.preventDefault();

    const block = apartmentBlock.trim();
    const refer = reference.trim();

    const deliveryAddress = {
      postalCode: contactForm.postalCode,
      addressStreet: contactForm.addressStreet,
      neighborhood: contactForm.neighborhood,
      city: contactForm.city,
      state: contactForm.state,

      house,
      apartment,
      addressNumber,
      apartmentNumber,
      apartmentBlock: block,
      reference: refer,

      complement: (
        (refer ? `${refer}, ` : "") +
        (apartment && block ? `Bloco: ${block}, ` : "") +
        (apartment ? `Apto: ${apartmentNumber}, ` : "")
      ).slice(0, -2),
    };

    dispatch(setToDelivery());
    dispatch(setDeliveryAddress(deliveryAddress));
    dispatch(searchDeliveryCalc());

    setDeliveryFormVisible(false);
    if (!isWorkingDelivery) {
      setSchedulerVisible(true);
    }
  };

  const cancelAddressForm = () => {
    setDeliveryFormVisible(false);
  };

  const onToGo = () => {
    dispatch(setToGo());
    if (!isWorkingToGo) {
      setToGoNotWorkingVisible(true);
    }
  };

  const addMoreProducts = () => {
    setDeliveryNotEnoughVisible(true);
    goTo("/");
  };
  const closeDeliveryNotEnough = () => setDeliveryNotEnoughVisible(false);
  const closeDeliveryNotWorking = () => setDeliveryNotWorkingVisible(false);
  const closeToGoNotWorking = () => setToGoNotWorkingVisible(false);

  const onToDelivery = (event, schedulingOrder) => {
    if (!isWorkingDelivery && !schedulingOrder) {
      setDeliveryNotWorkingVisible(true);
      return;
    }

    if (notEnoughToDelivery) {
      setDeliveryNotEnoughVisible(true);
      return;
    }

    setPostalCodeError(null);
    setHouse(deliveryAddress.house);
    setApartment(deliveryAddress.apartment);
    setPostalCode(deliveryAddress.postalCode.replace("-", ""));
    setAddressNumber(deliveryAddress.addressNumber);
    setApartmentNumber(deliveryAddress.apartmentNumber);
    setApartmentBlock(deliveryAddress.apartmentBlock);
    setReference(deliveryAddress.reference);
    setContactForm({ ...deliveryAddress });
    setDeliveryFormVisible(true);
  };

  const onSchedulingDeliveryOrder = () => {
    closeDeliveryNotWorking();
    onToDelivery(null, true);
  };

  const onSchedulingToGoOrder = () => {
    closeToGoNotWorking();
    setSchedulerVisible(true);
  };

  const onHouseClick = () => {
    setHouse(true);
    setApartment(false);
  };

  const onApartmentClick = () => {
    setHouse(false);
    setApartment(true);
  };

  const showInputs = house || apartment;

  return (
    <div className="p-3">
      {deliveryEnable && takeoutEnable && (
        <div className="d-flex justify-content-between">
          <TakeoutButton
            toGo={toGo}
            takeoutDiscount={takeoutDiscount}
            onClick={onToGo}
          />
          <div className="m-2" />
          <DeliveryButton
            toDelivery={toDelivery}
            deliveryMinimalCost={deliveryMinimalCost}
            onClick={onToDelivery}
            text="Entregar"
          />
        </div>
      )}
      {deliveryEnable && !takeoutEnable && (
        <DeliveryButton
          toDelivery={toDelivery}
          deliveryMinimalCost={deliveryMinimalCost}
          onClick={onToDelivery}
          text="Informar endereço"
        />
      )}
      {!deliveryEnable && takeoutEnable && (
        <TakeoutButton
          toGo={toGo}
          takeoutDiscount={takeoutDiscount}
          onClick={onToGo}
        />
      )}
      {toDelivery && (
        <div>
          <div>
            <p className="mt-3 mb-0 font-weight-bold">
              {deliveryAddress.addressStreet}, {deliveryAddress.addressNumber}
              {deliveryAddress.complement
                ? ", " + deliveryAddress.complement
                : ""}
            </p>
            <p className="m-0 text-muted">
              <small>{deliveryAddressLabel}</small>
            </p>
          </div>
          {!isSearchingLocation && deliveryError && (
            <p className="mt-1 mb-0 text-danger">
              <small>{deliveryError}</small>
            </p>
          )}
        </div>
      )}
      <Modal show={isDeliveryNotEnoughVisible} onHide={closeDeliveryNotEnough}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">Entregar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {`O pedido mínimo para entregar é de ${toPriceDisplay(
              deliveryMinimalCost
            )}.`}
          </p>
          <p>
            Adicione mais produtos ou selecione a opção 'Retirar no balcão'.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeDeliveryNotEnough}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={addMoreProducts}>
            Adicionar produtos
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isDeliveryNotWorkingVisible}
        onHide={closeDeliveryNotWorking}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h3">Entregar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>O serviço de entrega não está disponível no momento.</p>
          {shop.isOrderSchedulingEnabled && (
            <p>
              Você pode agendar o pedido para o proximo dia de funcionamento.
            </p>
          )}
          <WorkingTime
            label="Horário de funcionamento das entregas"
            rules={rulesDelivery}
            currentWeekDay={currentWeekDay}
          />
          <p className="pt-3"></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeDeliveryNotWorking}>
            Cancelar
          </Button>
          {shop.isOrderSchedulingEnabled && (
            <Button variant="danger" onClick={onSchedulingDeliveryOrder}>
              Agendar Pedido
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isDeliveryFormVisible} onHide={cancelAddressForm}>
        <Modal.Header closeButton>
          <Modal.Title as="h3">
            Endereço de entrega{" "}
            {isSearchingPostalCode && <Spinner animation="border" size="sm" />}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            <div className="pb-3 mb-3 d-flex justify-content-between">
              <Button
                className="w-100 mr-3 p-2"
                variant={house ? "danger" : "outline-danger"}
                onClick={onHouseClick}
              >
                <HomeIcon className="mr-2" />
                Casa
              </Button>
              <Button
                className="w-100 ml-3 p-2"
                variant={apartment ? "danger" : "outline-danger"}
                onClick={onApartmentClick}
              >
                <ApartmentIcon className="mr-2" />
                Prédio
              </Button>
            </div>
            {showInputs && !manualAddressEnable && (
              <Row>
                <Col xs="6">
                  <TextField
                    fullWidth={true}
                    style={styles.input}
                    variant="outlined"
                    label="CEP"
                    error={postalCodeError ? true : false}
                    helperText={postalCodeError}
                    value={postalCode}
                    onChange={onPostalCodeChange}
                    disabled={isSearchingPostalCode}
                    InputProps={{
                      inputComponent: CEPInput,
                    }}
                    required
                  />
                </Col>
                <Col xs="6">
                  <TextField
                    fullWidth={true}
                    style={styles.input}
                    variant="outlined"
                    label={house ? "Número da casa" : "Número do prédio"}
                    value={addressNumber}
                    onChange={onNumberChange}
                    InputProps={{
                      inputComponent: AddressNumberInput,
                    }}
                    required
                  />
                </Col>
              </Row>
            )}
            {showInputs && manualAddressEnable && (
              <>
                <FormControl
                  variant="outlined"
                  style={styles.input}
                  fullWidth={true}
                  required
                >
                  <InputLabel id="neighborhood-selector">Bairro</InputLabel>
                  <Select
                    native
                    labelId="neighborhood-selector"
                    id="neighborhood-selector"
                    value={contactForm.neighborhood}
                    onChange={onContactFormChange("neighborhood")}
                    label="Bairro"
                    required
                  >
                    <option aria-label="None" value="" />
                    {deliveryNeighborhoods.map((neighborhood) => (
                      <option key={neighborhood} value={neighborhood}>
                        {neighborhood}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth={true}
                  style={styles.input}
                  variant="outlined"
                  label={"Nome da Rua"}
                  value={contactForm.addressStreet}
                  onChange={onContactFormChange("addressStreet")}
                  maxLength={32}
                  required
                />
                <TextField
                  fullWidth={true}
                  style={styles.input}
                  variant="outlined"
                  label={house ? "Número da casa" : "Número do prédio"}
                  value={addressNumber}
                  onChange={onNumberChange}
                  InputProps={{
                    inputComponent: AddressNumberInput,
                  }}
                  required
                />
              </>
            )}
            {showInputs && (
              <TextField
                fullWidth={true}
                style={styles.input}
                variant="outlined"
                label={
                  house ? "Complemento" : "Nome do condomínio / complemento"
                }
                value={reference}
                onChange={onReferenceChange}
                maxLength={32}
              />
            )}
            {apartment && (
              <Row>
                <Col xs="6">
                  <TextField
                    fullWidth={true}
                    style={styles.input}
                    variant="outlined"
                    label="Bloco"
                    value={apartmentBlock}
                    onChange={onApartmentBlockChange}
                  />
                </Col>
                <Col xs="6">
                  <TextField
                    fullWidth={true}
                    style={styles.input}
                    variant="outlined"
                    label="Número do apto"
                    value={apartmentNumber}
                    onChange={onApartmentNumberChange}
                    InputProps={{
                      inputComponent: AddressNumberInput,
                    }}
                    required
                  />
                </Col>
              </Row>
            )}
            {!manualAddressEnable && contactForm.postalCode && (
              <div className="mt-3">
                <p className="m-0 font-weight-bold">
                  {contactForm.addressStreet}, {addressNumber}
                  {reference.trim() ? ", " + reference : ""}
                  {apartment && apartmentBlock.trim()
                    ? ", Bloco " + apartmentBlock
                    : ""}
                  {apartment ? ", Apto " + apartmentNumber : ""}
                </p>
                <p className="m-0 text-muted">
                  <small>
                    {contactForm.neighborhood}, {contactForm.city}/
                    {contactForm.state} - CEP {contactForm.postalCode}
                    <br />
                  </small>
                </p>
              </div>
            )}
          </Modal.Body>
          {(house || apartment) && (
            <Modal.Footer>
              {/* <Button variant="danger" onClick={cancelAddressForm}>
                Cancelar
              </Button> */}
              <Button
                className="w-100"
                variant="danger"
                type="submit"
                disabled={submitDisabled}
              >
                Salvar endereço
              </Button>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
      {isToGoNotWorkingVisible && (
        <Modal show={isToGoNotWorkingVisible} onHide={closeToGoNotWorking}>
          <Modal.Header closeButton>
            <Modal.Title as="h3">Retirar no balcão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              O serviço de retirada no balcão não está disponível no momento.
            </p>
            {shop.isOrderSchedulingEnabled && (
              <p>
                Você pode agendar o pedido para o proximo dia de funcionamento.
              </p>
            )}
            <WorkingTime
              label="Horário de funcionamento para retirada"
              rules={rulesToGo}
              currentWeekDay={currentWeekDay}
            />
            <p className="pt-3"></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={closeToGoNotWorking}>
              Cancelar
            </Button>
            {shop.isOrderSchedulingEnabled && (
              <Button variant="danger" onClick={onSchedulingToGoOrder}>
                Agendar Pedido
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
      {isSchedulerVisible && (
        <OrderSchedulerModal
          isOpen={isSchedulerVisible}
          onClose={onSchedulerClose}
          iSchedulingRequired
        />
      )}
    </div>
  );
}
